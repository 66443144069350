import React, { useState, useRef, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./grandma-harvest.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import wallper from "./images/wallper.png";
import basketBack from "./images/basketBack.png";
import basketFront from "./images/basketFront.png";
import beet from "./images/beet.png";
import blackberry from "./images/blackberry.png";
import blackberryBush from "./images/blackberryBush.png";
import cherry from "./images/cherry.png";
import cherryTree from "./images/cherryTree.png";
import cranberry from "./images/cranberry.png";
import cranberryBush from "./images/cranberryBush.png";
import grape from "./images/grape.png";
import kiwi from "./images/kiwi.png";
import kiwiTree from "./images/kiwiTree.png";
import plum from "./images/plum.png";
import plumTree from "./images/plumTree.png";
import pumpkin from "./images/pumpkin.png";
import vine from "./images/vine.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import dropBoxSound from "./sounds/dropBox.mp3";

const initialFruits = [
  { id: "cherry1", src: cherry, top: "30%", left: "90%", inBowl: false },
  { id: "cherry2", src: cherry, top: "15%", left: "25%", inBowl: false },
  { id: "cherry3", src: cherry, top: "20%", left: "30%", inBowl: false },
  { id: "cherry4", src: cherry, top: "25%", left: "35%", inBowl: false },
  { id: "cherry5", src: cherry, top: "25%", left: "35%", inBowl: false },
  { id: "cherry6", src: cherry, top: "25%", left: "35%", inBowl: false },

  { id: "beet", src: beet, top: "10%", left: "20%", inBowl: false },

  { id: "pumpkin", src: pumpkin, top: "10%", left: "20%", inBowl: false },

  {
    id: "blackberry1",
    src: blackberry,
    top: "10%",
    left: "20%",
    inBowl: false,
  },
  {
    id: "blackberry2",
    src: blackberry,
    top: "10%",
    left: "20%",
    inBowl: false,
  },
  {
    id: "blackberry3",
    src: blackberry,
    top: "10%",
    left: "20%",
    inBowl: false,
  },

  { id: "kiwi1", src: kiwi, top: "30%", left: "40%", inBowl: false },
  { id: "kiwi2", src: kiwi, top: "35%", left: "45%", inBowl: false },
  { id: "kiwi3", src: kiwi, top: "40%", left: "50%", inBowl: false },

  { id: "plum1", src: plum, top: "30%", left: "40%", inBowl: false },
  { id: "plum2", src: plum, top: "35%", left: "45%", inBowl: false },
  { id: "plum3", src: plum, top: "40%", left: "50%", inBowl: false },
  { id: "plum4", src: plum, top: "40%", left: "50%", inBowl: false },
  { id: "plum5", src: plum, top: "40%", left: "50%", inBowl: false },
  { id: "plum6", src: plum, top: "40%", left: "50%", inBowl: false },
  { id: "plum7", src: plum, top: "40%", left: "50%", inBowl: false },

  {
    id: "cranberry1",
    src: cranberry,
    top: "30%",
    left: "40%",
    inBowl: false,
  },
  {
    id: "cranberry2",
    src: cranberry,
    top: "35%",
    left: "45%",
    inBowl: false,
  },
  {
    id: "cranberry3",
    src: cranberry,
    top: "40%",
    left: "50%",
    inBowl: false,
  },
  {
    id: "cranberry4",
    src: cranberry,
    top: "40%",
    left: "50%",
    inBowl: false,
  },

  { id: "grape", src: grape, top: "30%", left: "40%", inBowl: false },
];

const GrandmaHarvestFirstPart = ({ onBoxFinished }) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [fruits, setFruits] = useState(initialFruits);
  const [fruitsInBowl, setFruitsInBowl] = useState([]);

  useEffect(() => {
    const allCollected = fruits.every((fruit) => fruit.inBowl);
    if (allCollected) {
      onBoxFinished();
    }
  }, [fruits, onBoxFinished]);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const dropBoxAudioRef = useRef(new Audio(dropBoxSound));

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  const getBowlPosition = (index) => {
    const cols = 3; // Количество фруктов в ряду
    const baseBottom = 17;
    const baseLeft = 16;
    const horizontalStep = 24; // Шаг по горизонтали
    const verticalStep = 7; // Шаг по вертикали

    const col = index % cols;
    const row = Math.floor(index / cols);

    const left = baseLeft + col * horizontalStep;
    const bottom = baseBottom + row * verticalStep;

    return { bottom: bottom, left: left };
  };
  const handleDrop = (itemId) => {
    if (dropBoxAudioRef.current) {
      dropBoxAudioRef.current.play();
    }
    setFruits((prevFruits) =>
      prevFruits.map((fruit) =>
        fruit.id === itemId ? { ...fruit, inBowl: true } : fruit
      )
    );
    setFruitsInBowl((prev) => [...prev, itemId]);
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (dropBoxAudioRef.current) {
        dropBoxAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      return newIsSoundOn;
    });
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      dropBoxAudioRef.current.pause();
      dropBoxAudioRef.current.currentTime = 0;
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}

      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.wallpaperWrapper}>
            <img src={wallper} alt="фон" className={styles.wallper} />
            <div className={styles.treesContainer}>
              <img
                src={blackberryBush}
                className={styles.blackberryBush}
                alt="куст"
              />
              <img
                src={cherryTree}
                className={styles.cherryTree}
                alt="дерево"
              />
              <img src={kiwiTree} className={styles.kiwiTree} alt="дерево" />
              <img src={plumTree} className={styles.plumTree} alt="дерево" />
              <img
                src={cranberryBush}
                className={styles.cranberryBush}
                alt="куст"
              />
              <img src={vine} className={styles.vine} alt="куст" />
            </div>
            <div
              className={styles.gameArea}
              style={{
                pointerEvents: isInteractionBlocked ? "none" : "auto",
              }}
            >
              {fruits.map(
                (fruit) =>
                  !fruit.inBowl && (
                    <DraggableItem
                      key={fruit.id}
                      id={fruit.id}
                      src={fruit.src}
                      top={fruit.top}
                      left={fruit.left}
                      className={styles[fruit.id]}
                    />
                  )
              )}
              <div className={styles.basketArea}>
                <img
                  src={basketBack}
                  alt="Корзина задняя часть"
                  className={styles.basketBack}
                />
                <DropTarget onDrop={handleDrop} className={styles.dropTarget} />
                {fruitsInBowl.map((fruitId, index) => {
                  const fruit = fruits.find((f) => f.id === fruitId);
                  const isLargeFruit = ["grape", "beet", "pumpkin"].includes(
                    fruit.id
                  );
                  const { bottom, left, row } = getBowlPosition(
                    index,
                    isLargeFruit
                  );

                  const fruitWidth = isLargeFruit ? "30%" : "30%";

                  const isHiddenFruit = index < 4;

                  return (
                    <img
                      key={fruit.id}
                      src={fruit.src}
                      alt={fruit.id}
                      className={`${styles.fruitInBowl} ${
                        isHiddenFruit ? styles.hiddenFruit : ""
                      }`}
                      style={{
                        position: "absolute",
                        bottom: `${bottom}%`,
                        left: `${left}%`,
                        width: fruitWidth,
                        zIndex: 3,
                      }}
                    />
                  );
                })}
                <img
                  src={basketFront}
                  alt="Корзина передняя часть"
                  className={styles.basketFront}
                />
              </div>
              <div
                className={styles.buttonsContainer}
                style={{
                  pointerEvents: "auto",
                }}
              >
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default GrandmaHarvestFirstPart;
