import React, { useState, useRef, useEffect } from "react";
import Confetti from "react-confetti";
import { gsap } from "gsap";
import EndGameOptions from "../../end-game-options/end-game-options";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import styles from "./shopping-four.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import coin1 from "./images/coin1.png";
import coin2 from "./images/coin2.png";
import coin3 from "./images/coin3.png";
import coin4 from "./images/coin4.png";
import coin5 from "./images/coin5.png";
import coin6 from "./images/coin6.png";
import coin7 from "./images/coin7.png";
import coin8 from "./images/coin8.png";
import coin9 from "./images/coin9.png";
import coin10 from "./images/coin10.png";
import cashBread from "./images/cashBread.png";
import cashMilk from "./images/cashMilk.png";
import cashVegetables from "./images/cashVegetables.png";
import cashFruits from "./images/cashFruits.png";
import cashSweets from "./images/cashSweets.png";

const tasks = [
  {
    id: 1,
    background: cashBread,
    items: [
      {
        src: coin4,
        alt: "монета",
        isCorrect: true,
      },
      {
        src: coin7,
        alt: "монета",
        isCorrect: false,
      },
      {
        src: coin5,
        alt: "монета",
        isCorrect: false,
      },
    ],
  },
  {
    id: 2,
    background: cashMilk,
    items: [
      {
        src: coin8,
        alt: "монета",
        isCorrect: false,
      },
      {
        src: coin7,
        alt: "монета",
        isCorrect: true,
      },
      {
        src: coin3,
        alt: "монета",
        isCorrect: false,
      },
    ],
  },

  {
    id: 3,
    background: cashVegetables,
    items: [
      {
        src: coin10,
        alt: "монета",
        isCorrect: true,
      },
      {
        src: coin1,
        alt: "монета",
        isCorrect: false,
      },
      {
        src: coin4,
        alt: "монета",
        isCorrect: false,
      },
    ],
  },
  {
    id: 4,
    background: cashFruits,
    items: [
      {
        src: coin5,
        alt: "монета",
        isCorrect: false,
      },
      {
        src: coin8,
        alt: "монета",
        isCorrect: true,
      },
      {
        src: coin10,
        alt: "монета",
        isCorrect: false,
      },
    ],
  },
  {
    id: 5,
    background: cashSweets,
    items: [
      {
        src: coin4,
        alt: "монета",
        isCorrect: false,
      },

      {
        src: coin8,
        alt: "монета",
        isCorrect: false,
      },
      {
        src: coin9,
        alt: "монета",
        isCorrect: true,
      },
    ],
  },
];

const ShoppingAheadFourPart = ({
  audioRefs,
  playBackgroundMusic,
  setBackgroundMusicVolume,
}) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [hasPlayedInstruction, setHasPlayedInstruction] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const currentTask = tasks[currentTaskIndex];
  const coinRefs = useRef([]);

  const {
    fourInstructionAudioRef,
    coinAudioRef,
    breadSumAudioRef,
    milkSumAudioRef,
    vegetablesSumAudioRef,
    fruitsSumAudioRef,
    sweetsSumAudioRef,
    praiseAudioRef,
    incorrectAnswerAudioRef,
  } = audioRefs;

  const taskAudioRefMap = {
    0: breadSumAudioRef,
    1: milkSumAudioRef,
    2: vegetablesSumAudioRef,
    3: fruitsSumAudioRef,
    4: sweetsSumAudioRef,
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
  });

  const audioRef = useRef(null);

  useEffect(() => {
    setIsInteractionBlocked(true);

    if (fourInstructionAudioRef && fourInstructionAudioRef.current) {
      fourInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        setHasPlayedInstruction(true);
        playBackgroundMusic();
        playTaskAudio(currentTaskIndex); // Передаём текущий индекс задачи
      };

      fourInstructionAudioRef.current.play().catch((error) => {
        setIsInteractionBlocked(false);
        setHasPlayedInstruction(true);
        playTaskAudio(currentTaskIndex); // Передаём текущий индекс задачи
      });
    } else {
      setIsInteractionBlocked(false);
      setHasPlayedInstruction(true);
      playTaskAudio(currentTaskIndex); // Передаём текущий индекс задачи
    }
  }, []);

  const playTaskAudio = (index) => {
    setIsInteractionBlocked(true);

    const audioRefToPlay = taskAudioRefMap[index];

    if (audioRefToPlay && audioRefToPlay.current) {
      audioRefToPlay.current.onended = () => {
        setIsInteractionBlocked(false);
      };

      audioRefToPlay.current.play().catch((error) => {
        setIsInteractionBlocked(false);
      });
    } else {
      setIsInteractionBlocked(false);
    }
  };

  const handleCoinClick = (item, index) => {
    if (isInteractionBlocked) return;

    if (item.isCorrect) {
      if (coinAudioRef.current) {
        coinAudioRef.current.currentTime = 0;
        coinAudioRef.current.play();
      }
      animateCorrectCoin(index);
    } else {
      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.currentTime = 0;
        incorrectAnswerAudioRef.current.play();
      }
      animateIncorrectCoin(index);
    }
  };

  const animateCorrectCoin = (index) => {
    setIsInteractionBlocked(true);

    const coinElement = coinRefs.current[index];

    gsap.to(coinElement, {
      y: -50,
      duration: 0.5,
      ease: "bounce.out",
      onComplete: () => {
        gsap.to(coinElement, {
          y: 0,
          duration: 0.5,
          ease: "bounce.out",
          onComplete: () => {
            if (currentTaskIndex < tasks.length - 1) {
              const newIndex = currentTaskIndex + 1;
              setCurrentTaskIndex(newIndex);
              playTaskAudio(newIndex);
            } else {
              if (praiseAudioRef.current) {
                praiseAudioRef.current.currentTime = 0;
                praiseAudioRef.current.play();
              }
              setShowConfetti(true);

              setTimeout(() => {
                setIsGameCompleted(true);
              }, 5000);
              setIsInteractionBlocked(false);
            }
          },
        });
      },
    });
  };

  const animateIncorrectCoin = (index) => {
    setIsInteractionBlocked(true);

    const coinElement = coinRefs.current[index];

    gsap.to(coinElement, {
      borderColor: "red",
      boxShadow: "0 0 10px red",
      duration: 0.5,
      onComplete: () => {
        gsap.to(coinElement, {
          borderColor: "transparent",
          boxShadow: "none",
          duration: 0.5,
          onComplete: () => {
            setIsInteractionBlocked(false);
          },
        });
      },
    });
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (fourInstructionAudioRef && fourInstructionAudioRef.current) {
        fourInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (coinAudioRef && coinAudioRef.current) {
        coinAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (breadSumAudioRef && breadSumAudioRef.current) {
        breadSumAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (milkSumAudioRef && milkSumAudioRef.current) {
        milkSumAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (vegetablesSumAudioRef && vegetablesSumAudioRef.current) {
        vegetablesSumAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (fruitsSumAudioRef && fruitsSumAudioRef.current) {
        fruitsSumAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (sweetsSumAudioRef && sweetsSumAudioRef.current) {
        sweetsSumAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (praiseAudioRef && praiseAudioRef.current) {
        praiseAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (incorrectAnswerAudioRef && incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      setBackgroundMusicVolume(newIsSoundOn ? 1 : 0);

      return newIsSoundOn;
    });
  };

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && <Confetti />}
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.backgroundContainer}>
            <img
              src={currentTask.background}
              alt="Фон задания"
              className={styles.wallper}
            />
            <div
              className={styles.coinsContainer}
              style={{
                pointerEvents: isInteractionBlocked ? "none" : "auto",
              }}
            >
              {currentTask.items.map((item, index) => (
                <img
                  key={index}
                  src={item.src}
                  alt={item.alt}
                  className={styles.coin}
                  onClick={() => handleCoinClick(item, index)}
                  ref={(el) => (coinRefs.current[index] = el)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <button
            className={styles.buttonsRepeatAndSoundOnOff}
            onClick={handleSoundOnOff}
          >
            <img src={isSoundOn ? dynamicImg : dynamicOff} alt="динамик" />
            <span className={styles.tooltip}>Включить / выключить звук</span>
          </button>
        </div>
      </div>

      <audio ref={audioRef} />
      {/* {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )} */}
    </>
  );
};

export default ShoppingAheadFourPart;
