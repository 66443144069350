import React, { useState, useRef, useEffect } from "react";

import { gsap } from "gsap";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./grandma-second-part.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import repeatTaskImg from "./images/repeatTask.png";
import wallperSecondPart from "./images/wallperSecondPart.png";
import grandma from "./images/grandma.png";
import soup from "./images/soup.png";
import jam from "./images/jam.png";
import salad from "./images/salad.png";
import compote from "./images/compote.png";
import cocktail from "./images/cocktail.png";
import maffin from "./images/maffin.png";
import cake from "./images/cake.png";
import juice from "./images/juice.png";
import closedEyes from "./images/closedEyes.png";
import cardBeet from "./images/cardBeet.png";
import cardBlackberry from "./images/cardBlackberry.png";
import cardCherry from "./images/cardCherry.png";
import cardCranberry from "./images/cardCranberry.png";
import cardGrape from "./images/cardGrape.png";
import cardKiwi from "./images/cardKiwi.png";
import cardPlum from "./images/cardPlum.png";
import cardPumpkin from "./images/cardPumpkin.png";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const tasks = [
  {
    mainImage: {
      src: soup,
      alt: "суп",
      width: "11%",
    },
    images: [
      { src: cardPumpkin, alt: "тыква" },
      { src: cardPlum, alt: "слива" },
      { src: cardBeet, alt: "свекла" },
    ],
    correctAnswer: "тыква",
  },
  {
    mainImage: {
      src: jam,
      alt: "варенье",
      width: "11%",
    },
    images: [
      { src: cardCherry, alt: "вишня" },
      { src: cardPlum, alt: "слива" },
      { src: cardGrape, alt: "виноград" },
    ],
    correctAnswer: "слива",
  },

  {
    mainImage: {
      src: juice,
      alt: "сок",
      width: "5%",
    },
    images: [
      { src: cardBlackberry, alt: "ежевика" },
      { src: cardKiwi, alt: "киви" },
      { src: cardGrape, alt: "виноград" },
    ],
    correctAnswer: "виноград",
  },
  {
    mainImage: {
      src: compote,
      alt: "компот",
      width: "10%",
    },
    images: [
      { src: cardBlackberry, alt: "ежевика" },
      { src: cardBeet, alt: "свекла" },
      { src: cardCranberry, alt: "клюква" },
    ],
    correctAnswer: "клюква",
  },
  {
    mainImage: {
      src: cake,
      alt: "торт",
      width: "10%",
    },
    images: [
      { src: cardGrape, alt: "виноград" },
      { src: cardCranberry, alt: "клюква" },
      { src: cardCherry, alt: "вишня" },
    ],
    correctAnswer: "вишня",
  },
  {
    mainImage: {
      src: cocktail,
      alt: "коктейль",
      width: "7%",
    },
    images: [
      { src: cardBlackberry, alt: "ежевика" },
      { src: cardKiwi, alt: "киви" },
      { src: cardBeet, alt: "свекла" },
    ],
    correctAnswer: "киви",
  },
  {
    mainImage: {
      src: maffin,
      alt: "маффин",
      width: "10%",
    },
    images: [
      { src: cardGrape, alt: "виноград" },
      { src: cardBlackberry, alt: "ежевика" },
      { src: cardCherry, alt: "вишня" },
    ],
    correctAnswer: "ежевика",
  },
  {
    mainImage: {
      src: salad,
      alt: "салат",
      width: "10%",
    },
    images: [
      { src: cardPumpkin, alt: "тыква" },
      { src: cardPlum, alt: "слива" },
      { src: cardBeet, alt: "свелка" },
    ],
    correctAnswer: "свелка",
  },
];

const GrandmaHarvestSecondPart = ({ taskAudioRefs, audioRefs }) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);

  const grandmaRef = useRef(null);
  const closedEyesRef = useRef(null);
  const currentTask = tasks[currentTaskIndex];
  const cardRefs = useRef([]);
  const audioRef = useRef(null);

  const {
    secondInstructionAudioRef,
    correctAnswerAudioRef,
    incorrectAnswerAudioRef,
    praiseAudioRef,
  } = audioRefs;

  useEffect(() => {
    setIsInteractionBlocked(true);
    if (secondInstructionAudioRef.current) {
      secondInstructionAudioRef.current.play();
      secondInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        playCurrentTaskAudio(currentTaskIndex); // Передаем текущий индекс
      };
    } else {
      setIsInteractionBlocked(false);
      playCurrentTaskAudio(currentTaskIndex); // Передаем текущий индекс
    }
  }, []);

  const playCurrentTaskAudio = (index) => {
    setIsInteractionBlocked(true);
    const audio = taskAudioRefs.current[index];
    if (audio) {
      audio.play();
      audio.onended = () => {
        setIsInteractionBlocked(false);
      };
    } else {
      setIsInteractionBlocked(false);
    }
  };

  const getMainImagePositionClass = (alt) => {
    switch (alt) {
      case "сок":
        return styles.juicePosition;
      case "коктейль":
        return styles.cocktailPosition;
      default:
        return "";
    }
  };

  useEffect(() => {
    const blinkAnimation = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    blinkAnimation
      .to(closedEyesRef.current, {
        opacity: 1,
        duration: 0.1,
      })
      .to(closedEyesRef.current, {
        opacity: 0,
        duration: 0.1,
        delay: 0.1,
      });
  }, []);

  const handleCardClick = (alt, index) => {
    if (isInteractionBlocked) return;

    setIsInteractionBlocked(true);

    const isCorrect = alt === currentTask.correctAnswer;

    if (isCorrect) {
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.currentTime = 0;
        correctAnswerAudioRef.current.play();
      }
      gsap.fromTo(
        cardRefs.current[index],
        { scale: 1 },
        {
          scale: 1.2,
          duration: 0.5,
          ease: "elastic.out(1, 0.3)",
          onComplete: () => {
            // Возвращаем масштаб обратно
            gsap.to(cardRefs.current[index], {
              scale: 1,
              duration: 0.5,
              onComplete: () => {
                // Переход к следующей задаче
                proceedToNextTask();
              },
            });
          },
        }
      );
    } else {
      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.currentTime = 0;
        incorrectAnswerAudioRef.current.play();
      }
      gsap.fromTo(
        cardRefs.current[index],
        { boxShadow: "0 0 0px red" },
        {
          boxShadow: "0 0 20px red",
          duration: 0.2,
          repeat: 1,
          yoyo: true,
          onComplete: () => {
            setIsInteractionBlocked(false);
          },
        }
      );
    }
  };

  const proceedToNextTask = () => {
    setTimeout(() => {
      if (currentTaskIndex + 1 < tasks.length) {
        const newIndex = currentTaskIndex + 1;
        setCurrentTaskIndex(newIndex);
        playCurrentTaskAudio(newIndex);
      } else {
        triggerFireworks();
        if (praiseAudioRef.current) {
          praiseAudioRef.current.play();
        }
        setTimeout(() => {
          setIsGameCompleted(true);
        }, 3000);
      }
    }, 500);
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (secondInstructionAudioRef.current) {
        secondInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (praiseAudioRef.current) {
        praiseAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (taskAudioRefs.current && taskAudioRefs.current.length > 0) {
        taskAudioRefs.current.forEach((audio) => {
          if (audio) {
            audio.volume = newIsSoundOn ? 1 : 0;
          }
        });
      }

      return newIsSoundOn;
    });
  };

  const handleRepeatTask = () => {
    const currentTaskAudio = taskAudioRefs.current[currentTaskIndex];

    if (currentTaskAudio && !isInteractionBlocked) {
      setIsInteractionBlocked(true); // Блокируем повторное нажатие кнопки
      currentTaskAudio.currentTime = 0; // Перематываем трек на начало
      currentTaskAudio.play(); // Запускаем воспроизведение

      currentTaskAudio.onended = () => {
        setIsInteractionBlocked(false); // Разблокируем после завершения
      };
    }
  };

  return (
    <>
      {isGameCompleted && <EndGameOptions />}

      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.wallpaperWrapper}>
            <img
              className={styles.wallper}
              src={wallperSecondPart}
              alt="обои"
            />
            <div className={styles.absoluteContainer}>
              <div className={styles.grandmaContainer} ref={grandmaRef}>
                <img src={grandma} alt="бабушка" className={styles.grandma} />

                <img
                  src={closedEyes}
                  alt="моргание"
                  className={styles.closedEyes}
                  ref={closedEyesRef}
                />
              </div>
              <div
                className={`${
                  styles.mainImageContainer
                } ${getMainImagePositionClass(currentTask.mainImage.alt)}`}
              >
                <img
                  src={currentTask.mainImage.src}
                  alt={currentTask.mainImage.alt}
                  className={styles.mainImage}
                  style={{ width: currentTask.mainImage.width || "auto" }}
                />
              </div>
              <div className={styles.cardsContainer}>
                {currentTask.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={styles.cardImage}
                    style={{ width: image.width || "30%" }}
                    onClick={() => handleCardClick(image.alt, index)}
                    ref={(el) => (cardRefs.current[index] = el)}
                  />
                ))}
              </div>

              <div className={styles.buttonsContainer}>
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>

                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleRepeatTask}
                >
                  <img src={repeatTaskImg} alt="повтор звука" />
                  <span className={styles.tooltip}>
                    Нажмите для повторного прослушивания инструкции
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className={styles.fireworksContainer}></div>
        </div>
      </div>

      <audio ref={audioRef} />
    </>
  );
};
export default GrandmaHarvestSecondPart;
