import React, { useState, useRef, useEffect } from "react";
import GrandmaHarvestFirstPart from "./grandma-harvest";
import GrandmaHarvestSecondPart from "./grandma-second-part";

import secondInstructionSound from "./sounds/secondInstruction.mp3";
import correctAnswerSound from "./sounds/yes.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import praiseSound from "./sounds/praise.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskOneSound from "./sounds/taskOne.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";
import taskSixSound from "./sounds/taskSix.mp3";
import taskSevenSound from "./sounds/taskSevenSound.mp3";

const GranmaHarvestGame = () => {
  const [scene, setScene] = useState("GrandmaHarvestFirstPart");

  const secondInstructionAudioRef = useRef(null);
  const correctAnswerAudioRef = useRef(null);
  const incorrectAnswerAudioRef = useRef(null);
  const praiseAudioRef = useRef(null);
  const taskAudioRefs = useRef([]);

  const taskSoundFiles = [
    taskZeroSound,
    taskSevenSound,
    taskOneSound,
    taskTwoSound,
    taskThreeSound,
    taskFourSound,
    taskFiveSound,
    taskSixSound,
  ];

  useEffect(() => {
    secondInstructionAudioRef.current = new Audio(secondInstructionSound);
    correctAnswerAudioRef.current = new Audio(correctAnswerSound);
    incorrectAnswerAudioRef.current = new Audio(incorrectAnswerSound);
    praiseAudioRef.current = new Audio(praiseSound);
    taskAudioRefs.current = taskSoundFiles.map(
      (soundFile) => new Audio(soundFile)
    );

    return () => {
      if (secondInstructionAudioRef.current) {
        secondInstructionAudioRef.current.pause();
        secondInstructionAudioRef.current.currentTime = 0;
      }

      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.pause();
        correctAnswerAudioRef.current.currentTime = 0;
      }
      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.pause();
        incorrectAnswerAudioRef.current.currentTime = 0;
      }
      if (praiseAudioRef.current) {
        praiseAudioRef.current.pause();
        praiseAudioRef.current.currentTime = 0;
      }

      if (taskAudioRefs.current && taskAudioRefs.current.length > 0) {
        taskAudioRefs.current.forEach((audio) => {
          if (audio) {
            audio.pause();
            audio.currentTime = 0;
          }
        });
      }
    };
  }, []);

  const handleSceneChange = () => {
    setScene("GrandmaHarvestSecondPart");
  };

  return (
    <div>
      {scene === "GrandmaHarvestFirstPart" && (
        <GrandmaHarvestFirstPart onBoxFinished={handleSceneChange} />
      )}
      {scene === "GrandmaHarvestSecondPart" && (
        <GrandmaHarvestSecondPart
          audioRefs={{
            secondInstructionAudioRef,
            correctAnswerAudioRef,
            incorrectAnswerAudioRef,
            praiseAudioRef,
          }}
          taskAudioRefs={taskAudioRefs}
        />
      )}
    </div>
  );
};

export default GranmaHarvestGame;
