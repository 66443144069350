import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import styles from "./shopping-third.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import wallperCashier from "./images/wallperCashier.png";
import mother from "./images/mother.png";
import cashier from "./images/cashier.png";
import motherClosedEyes from "./images/motherClosedEyes.png";
import cashierClosedEyes from "./images/cashierClosedEyes.png";
import bag from "./images/bag.png";
import bagWithProducts from "./images/bagWithProducts.png";

import bread from "./images/bread.png";
import bublik from "./images/bublik.png";
import breadLong from "./images/breadLong.png";
import plombir from "./images/plombir.png";
import milk from "./images/milk.png";
import kefir from "./images/kefir.png";
import cucumber from "./images/cucumber.png";
import mandarin from "./images/mandarin.png";
import onion from "./images/onion.png";
import cabbage from "./images/cabbage.png";
import orange from "./images/orange.png";
import pineapple from "./images/pineapple.png";
import chocolate from "./images/chocolate.png";
import cookies from "./images/cookies.png";
import lemonade from "./images/lemonade.png";

const products = [
  {
    id: 1,
    src: breadLong,
    alt: "хлеб",
    position: { top: "56%", left: "54%" },
    style: { width: "5%", minWidth: "70px", zIndex: 3 },
    order: 1,
  },
  {
    id: 2,
    src: bread,
    alt: "хлеб",
    position: { top: "58%", left: "50%" },
    style: { width: "3%", minWidth: "40px", zIndex: 3 },
    order: 2,
  },
  {
    id: 3,
    src: bublik,
    alt: "бублик",
    position: { top: "56%", left: "49%" },
    style: { width: "3%", minWidth: "40px", zIndex: 3 },
    order: 3,
  },
  {
    id: 4,
    src: milk,
    alt: "молоко",
    position: { top: "47%", left: "45%" },
    style: { width: "4%", minWidth: "50px", zIndex: 3 },
    order: 4,
  },
  {
    id: 5,
    src: kefir,
    alt: "кефир",
    position: { top: "51%", left: "45%" },
    style: { width: "4%", minWidth: "50px", zIndex: 3 },

    order: 5,
  },
  {
    id: 6,
    src: plombir,
    alt: "пломбир",
    position: { top: "53%", left: "40%" },
    style: { width: "5%", minWidth: "60px", zIndex: 3 },
    order: 6,
  },
  {
    id: 7,
    src: cucumber,
    alt: "огурец",
    position: { top: "57%", left: "40%" },
    style: { width: "4%", minWidth: "50px", zIndex: 3 },
    order: 7,
  },
  {
    id: 8,
    src: cabbage,
    alt: "капуста",
    position: { top: "53%", left: "37%" },
    style: { width: "4%", minWidth: "50px", zIndex: 3 },
    order: 8,
  },
  {
    id: 9,
    src: onion,
    alt: "лук",
    position: { top: "57%", left: "37%" },
    style: { width: "3%", minWidth: "30px", zIndex: 3 },
    order: 9,
  },
  {
    id: 10,
    src: pineapple,
    alt: "ананас",
    position: { top: "53%", left: "36%" },
    style: { width: "5%", minWidth: "30px", zIndex: 3 },
    order: 10,
  },
  {
    id: 11,
    src: orange,
    alt: "апельсин",
    position: { top: "53%", left: "36%" },
    style: { width: "3%", minWidth: "30px", zIndex: 3 },
    order: 11,
  },
  {
    id: 12,
    src: mandarin,
    alt: "мандарин",
    position: { top: "58%", left: "35%" },
    style: { width: "3%", minWidth: "30px", zIndex: 3 },
    order: 12,
  },
  {
    id: 13,
    src: lemonade,
    alt: "лимонад",
    position: { top: "48%", left: "33%" },
    style: { width: "3%", minWidth: "35px", zIndex: 3 },
    order: 13,
  },
  {
    id: 14,
    src: chocolate,
    alt: "шоколад",
    position: { top: "55%", left: "33%" },
    style: { width: "4%", minWidth: "35px", zIndex: 3 },
    order: 14,
  },
  {
    id: 15,
    src: cookies,
    alt: "печенье",
    position: { top: "55%", left: "30%" },
    style: { width: "4%", minWidth: "35px", zIndex: 3 },
    order: 15,
  },
];

const ShoppingAheadThirdPart = ({
  onNextScene,
  audioRefs,
  playBackgroundMusic,
  setBackgroundMusicVolume,
}) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [productsState, setProductsState] = useState(products);
  const [bagImage, setBagImage] = useState(bag);
  const timeoutRef = useRef(null);
  const motherRef = useRef(null);
  const motherClosedEyesRef = useRef(null);
  const cashierRef = useRef(null);
  const cashierClosedEyesRef = useRef(null);
  const bagRef = useRef(null);
  gsap.registerPlugin(MotionPathPlugin);

  const audioRef = useRef(null);

  const { thirdInstructionAudioRef, cashierAudioRef, bagAudioRef } = audioRefs;

  useEffect(() => {
    setIsInteractionBlocked(true);

    if (thirdInstructionAudioRef && thirdInstructionAudioRef.current) {
      thirdInstructionAudioRef.current.play();

      thirdInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        playBackgroundMusic();
      };
    } else {
      setIsInteractionBlocked(false);
    }
  }, []);

  useEffect(() => {
    const blinkAnimation = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    blinkAnimation
      .to(motherClosedEyesRef.current, {
        opacity: 1,

        duration: 0.1,
      })
      .to(motherClosedEyesRef.current, {
        opacity: 0,
        duration: 0.1,
        delay: 0.1,
      });
  }, []);

  useEffect(() => {
    const blinkAnimation = gsap.timeline({
      repeat: -1,
      repeatDelay: 2,
      delay: 0.5,
    });
    blinkAnimation
      .to(cashierClosedEyesRef.current, {
        opacity: 1,

        duration: 0.1,
      })
      .to(cashierClosedEyesRef.current, {
        opacity: 0,
        duration: 0.1,
        delay: 0.1,
      });
  }, []);

  const handleProductClick = (product, event) => {
    const productElement = product.ref;

    const productRect = productElement.getBoundingClientRect();
    const bagRect = bagRef.current.getBoundingClientRect();

    if (cashierAudioRef.current) {
      cashierAudioRef.current.currentTime = 0;
      cashierAudioRef.current.play();
    }

    const path = [
      { x: 0, y: 0 },
      {
        x: bagRect.left - productRect.left,
        y: bagRect.top - productRect.top,
      },
    ];

    gsap.to(productElement, {
      duration: 1.5,
      ease: "power1.inOut",
      motionPath: {
        path: path,
        align: false,
      },
      onComplete: () => {
        if (bagAudioRef.current) {
          bagAudioRef.current.currentTime = 0;
          bagAudioRef.current.play();
        }
        setProductsState((prevProducts) => {
          const updatedProducts = prevProducts.filter(
            (p) => p.id !== product.id
          );

          if (updatedProducts.length === 0) {
            setBagImage(bagWithProducts);

            timeoutRef.current = setTimeout(() => {
              onNextScene();
            }, 2000);
          }

          return updatedProducts;
        });
      },
    });
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (thirdInstructionAudioRef.current) {
        thirdInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (cashierAudioRef.current) {
        cashierAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (bagAudioRef.current) {
        bagAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      setBackgroundMusicVolume(newIsSoundOn ? 1 : 0);

      return newIsSoundOn;
    });
  };

  return (
    <>
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.wallpaperWrapper}>
            <img className={styles.wallper} src={wallperCashier} alt="обои" />
            <div
              className={styles.absoluteContainer}
              style={{
                pointerEvents: isInteractionBlocked ? "none" : "auto",
              }}
            >
              <div className={styles.motherContainer} ref={motherRef}>
                <img src={mother} alt="мама" className={styles.mother} />
                <img
                  src={motherClosedEyes}
                  alt="моргание"
                  className={styles.motherClosedEyes}
                  ref={motherClosedEyesRef}
                />
              </div>

              <div className={styles.cashierContainer} ref={cashierRef}>
                <img src={cashier} alt="кассир" className={styles.cashier} />
                <img
                  src={cashierClosedEyes}
                  alt="моргание"
                  className={styles.cashierClosedEyes}
                  ref={cashierClosedEyesRef}
                />
              </div>
              <img
                src={bagImage}
                alt="сумка"
                className={styles.bag}
                ref={bagRef}
              />

              {productsState.map((product) => (
                <img
                  key={product.id}
                  src={product.src}
                  alt={product.alt}
                  style={{
                    position: "absolute",
                    ...product.position,
                    ...product.style,
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleProductClick(product, e)}
                  ref={(el) => (product.ref = el)}
                />
              ))}

              <div
                className={styles.buttonsContainer}
                style={{
                  pointerEvents: "auto",
                }}
              >
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {/* {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )} */}
    </>
  );
};

export default ShoppingAheadThirdPart;
