import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import styles from "./shopping-ahead.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import storeEntrance from "./images/storeEntrance.png";
import mother from "./images/mother.png";

import startInstructionSound from "./sounds/startInstruction.mp3";

const ShoppingAheadFirstPart = ({
  onNextScene,
  playBackgroundMusic,
  setBackgroundMusicVolume,
}) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isMotherVisible, setIsMotherVisible] = useState(true);

  const motherRef = useRef(null);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);

        playBackgroundMusic();
        gsap.to(motherRef.current, {
          duration: 2,
          x: 200,
          opacity: 0,
          ease: "power2.inOut",
          onComplete: () => {
            setIsMotherVisible(false);
            setIsInteractionBlocked(false);
            onNextScene();
          },
        });
      };
    }

    setIsButtonVisible(false);
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      setBackgroundMusicVolume(newIsSoundOn ? 1 : 0);

      return newIsSoundOn;
    });
  };

  useEffect(() => {
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <>
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.wallpaperWrapper}>
            <img className={styles.wallper} src={storeEntrance} alt="обои" />
            <div className={styles.absoluteContainer}>
              {isMotherVisible && (
                <div className={styles.motherContainer}>
                  <img
                    src={mother}
                    alt="мама"
                    className={styles.mother}
                    ref={motherRef}
                  />
                </div>
              )}

              <div className={styles.buttonsContainer}>
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default ShoppingAheadFirstPart;
