import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import useDynamicHeight from "../../../hooks/useDynamicHeight";

import styles from "./shopping-ahead.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import repeatTaskImg from "./images/repeatTask.png";
import wallperBread from "./images/wallperBread.png";
import pie from "./images/pie.png";
import krendel from "./images/krendel.png";
import vatrushka from "./images/vatrushka.png";
import breadHeart from "./images/breadHeart.png";
import breadSprinkle from "./images/breadSprinkle.png";
import bread from "./images/bread.png";
import bublik from "./images/bublik.png";
import breadLong from "./images/breadLong.png";

import wallperMilk from "./images/wallperMilk.png";
import pack from "./images/pack.png";
import cheese from "./images/cheese.png";
import ryazhenka from "./images/ryazhenka.png";
import plombir from "./images/plombir.png";
import milk from "./images/milk.png";
import oil from "./images/oil.png";
import kefir from "./images/kefir.png";

import wallperFruitVegetable from "./images/wallperFruitVegetable.png";
import appleRed from "./images/appleRed.png";
import appleYellow from "./images/appleYellow.png";
import pumpkin from "./images/pumpkin.png";
import beet from "./images/beet.png";
import tomato from "./images/tomato.png";
import cucumber from "./images/cucumber.png";
import mandarin from "./images/mandarin.png";
import onion from "./images/onion.png";
import potato from "./images/potato.png";
import cabbage from "./images/cabbage.png";
import zucchini from "./images/zucchini.png";
import pear from "./images/pear.png";
import pomegranate from "./images/pomegranate.png";
import waterlemon from "./images/waterlemon.png";
import orange from "./images/orange.png";
import pineapple from "./images/pineapple.png";

import wallperSweets from "./images/wallperSweets.png";
import chocolate from "./images/chocolate.png";
import halva from "./images/halva.png";
import gingerbread from "./images/gingerbread.png";
import cookies from "./images/cookies.png";
import lemonade from "./images/lemonade.png";
import lollipops from "./images/lollipops.png";

const tasks = [
  {
    id: 0,
    background: wallperBread,
    items: [
      {
        id: 1,
        src: pie,
        alt: "пирог",
        position: { top: "49%", left: "26%" },
        style: { width: "8%" },
        isCorrect: false,
      },
      {
        id: 2,
        src: pie,
        alt: "пирог",
        position: { top: "41%", left: "35%" },
        style: { width: "8%" },
        isCorrect: false,
      },
      {
        id: 3,
        src: pie,
        alt: "пирог",
        position: { top: "52%", left: "38%" },
        style: { width: "8%" },
        isCorrect: false,
      },
      {
        id: 4,
        src: pie,
        alt: "пирог",
        position: { top: "43%", left: "47%" },
        style: { width: "8%" },
        isCorrect: false,
      },
      {
        id: 5,
        src: breadLong,
        alt: "хлеб длинный",
        position: { top: "18%", left: "14%" },
        style: { width: "17%" },
        isCorrect: true,
      },
      {
        id: 6,
        src: vatrushka,
        alt: "ватрушка",
        position: { top: "19%", left: "84%" },
        style: { width: "10%" },
        isCorrect: false,
      },
      {
        id: 7,
        src: vatrushka,
        alt: "ватрушка",
        position: { top: "19%", left: "70%" },
        style: { width: "10%" },
        isCorrect: false,
      },
      {
        id: 8,
        src: vatrushka,
        alt: "ватрушка",
        position: { top: "19%", left: "56%" },
        style: { width: "10%" },
        isCorrect: false,
      },
      {
        id: 9,
        src: bread,
        alt: "хлеб",
        position: { top: "17%", left: "37%" },
        style: { width: "14%" },
        isCorrect: true,
      },
      {
        id: 10,
        src: bublik,
        alt: "бублик",
        position: { top: "42%", left: "8%" },
        style: { width: "12%" },
        isCorrect: true,
      },
      {
        id: 11,
        src: breadHeart,
        alt: "хлеб сердце",
        position: { top: "42%", left: "76%" },
        style: { width: "12%" },
        isCorrect: false,
      },
      {
        id: 12,
        src: breadHeart,
        alt: "хлеб сердце",
        position: { top: "42%", left: "60%" },
        style: { width: "12%" },
        isCorrect: false,
      },
      {
        id: 13,
        src: krendel,
        alt: "крендель",
        position: { top: "69%", left: "8%" },
        style: { width: "12%" },
        isCorrect: false,
      },
      {
        id: 14,
        src: krendel,
        alt: "крендель",
        position: { top: "69%", left: "23%" },
        style: { width: "12%" },
        isCorrect: false,
      },
      {
        id: 15,
        src: krendel,
        alt: "крендель",
        position: { top: "69%", left: "38%" },
        style: { width: "12%" },
        isCorrect: false,
      },
      {
        id: 16,
        src: breadSprinkle,
        alt: "хлеб с маком",
        position: { top: "68%", left: "53%" },
        style: { width: "12%" },
        isCorrect: false,
      },
      {
        id: 17,
        src: breadSprinkle,
        alt: "хлеб с маком",
        position: { top: "68%", left: "67%" },
        style: { width: "12%" },
        isCorrect: false,
      },
      {
        id: 18,
        src: breadSprinkle,
        alt: "хлеб с маком",
        position: { top: "68%", left: "82%" },
        style: { width: "12%" },
        isCorrect: false,
      },
    ],
  },
  {
    id: 1,
    background: wallperMilk,
    items: [
      {
        id: 1,
        src: kefir,
        alt: "кефир",
        position: { top: "12%", left: "6%" },
        style: { width: "9%", minWidth: "112px" },
        isCorrect: true,
      },
      {
        id: 2,
        src: milk,
        alt: "молоко",
        position: { top: "12%", left: "25%" },
        style: { width: "9%", minWidth: "112px" },
        isCorrect: true,
      },
      {
        id: 3,
        src: oil,
        alt: "масло",
        position: { top: "25%", left: "41%" },
        style: { width: "10%", minWidth: "120px" },
        isCorrect: false,
      },
      {
        id: 4,
        src: oil,
        alt: "масло",
        position: { top: "25%", left: "55%" },
        style: { width: "10%", minWidth: "120px" },
        isCorrect: false,
      },
      {
        id: 5,
        src: plombir,
        alt: "пломбир",
        position: { top: "20%", left: "73%" },
        style: { width: "15%", minWidth: "180px" },
        isCorrect: true,
      },
      {
        id: 6,
        src: ryazhenka,
        alt: "ряженка",
        position: { top: "40%", left: "7%" },
        style: { width: "8%", minWidth: "100px" },
        isCorrect: false,
      },
      {
        id: 7,
        src: ryazhenka,
        alt: "ряженка",
        position: { top: "40%", left: "21%" },
        style: { width: "8%", minWidth: "100px" },
        isCorrect: false,
      },
      {
        id: 8,
        src: ryazhenka,
        alt: "ряженка",
        position: { top: "40%", left: "35%" },
        style: { width: "8%", minWidth: "100px" },
        isCorrect: false,
      },
      {
        id: 9,
        src: cheese,
        alt: "сыр",
        position: { top: "46%", left: "48%" },
        style: { width: "13%", minWidth: "150px" },
        isCorrect: false,
      },
      {
        id: 10,
        src: cheese,
        alt: "сыр",
        position: { top: "46%", left: "62%" },
        style: { width: "13%", minWidth: "150px" },
        isCorrect: false,
      },
      {
        id: 11,
        src: cheese,
        alt: "сыр",
        position: { top: "46%", left: "76%" },
        style: { width: "13%", minWidth: "150px" },
        isCorrect: false,
      },
      {
        id: 12,
        src: pack,
        alt: "пак",
        position: { top: "65%", left: "12%" },
        style: { width: "9%" },
        isCorrect: false,
      },
      {
        id: 13,
        src: pack,
        alt: "пак",
        position: { top: "65%", left: "26%" },
        style: { width: "9%" },
        isCorrect: false,
      },
      {
        id: 14,
        src: pack,
        alt: "пак",
        position: { top: "65%", left: "40%" },
        style: { width: "9%" },
        isCorrect: false,
      },
      {
        id: 15,
        src: pack,
        alt: "пак",
        position: { top: "65%", left: "54%" },
        style: { width: "9%" },
        isCorrect: false,
      },
      {
        id: 16,
        src: pack,
        alt: "пак",
        position: { top: "65%", left: "68%" },
        style: { width: "9%" },
        isCorrect: false,
      },
      {
        id: 17,
        src: pack,
        alt: "пак",
        position: { top: "65%", left: "82%" },
        style: { width: "9%" },
        isCorrect: false,
      },
    ],
  },
  {
    id: 3,
    background: wallperFruitVegetable,
    items: [
      {
        id: 1,
        src: zucchini,
        alt: "кабачок",
        position: { top: "17%", left: "32%" },
        style: { width: "10%", minWidth: "112px" },
        isCorrect: false,
      },
      {
        id: 2,
        src: zucchini,
        alt: "кабачок",
        position: { top: "17%", left: "42%" },
        style: { width: "10%", minWidth: "112px" },
        isCorrect: false,
      },
      {
        id: 3,
        src: beet,
        alt: "свекла",
        position: { top: "11%", left: "54%" },
        style: { width: "9%", minWidth: "120px" },
        isCorrect: false,
      },
      {
        id: 4,
        src: potato,
        alt: "картофель",
        position: { top: "13%", left: "66%" },
        style: { width: "15%", minWidth: "180px" },
        isCorrect: false,
      },
      {
        id: 5,
        src: cucumber,
        alt: "огурец",
        position: { top: "33%", left: "32%" },
        style: { width: "13%", minWidth: "100px" },
        isCorrect: true,
      },
      {
        id: 6,
        src: cabbage,
        alt: "капуста",
        position: { top: "31%", left: "47%" },
        style: { width: "10%", minWidth: "100px" },
        isCorrect: true,
      },
      {
        id: 7,
        src: cabbage,
        alt: "капуста",
        position: { top: "70%", left: "58%" },
        style: { width: "10%", minWidth: "140px" },
        isCorrect: true,
      },
      {
        id: 8,
        src: pumpkin,
        alt: "тыква",
        position: { top: "31%", left: "57%" },
        style: { width: "10%", minWidth: "100px" },
        isCorrect: false,
      },
      {
        id: 9,
        src: tomato,
        alt: "помидор",
        position: { top: "33%", left: "68%" },
        style: { width: "6%", minWidth: "1px" },
        isCorrect: false,
      },
      {
        id: 10,
        src: onion,
        alt: "лук",
        position: { top: "30%", left: "76%" },
        style: { width: "7%", minWidth: "1px" },
        isCorrect: true,
      },
      {
        id: 11,
        src: zucchini,
        alt: "кабачок",
        position: { top: "73%", left: "39%" },
        style: { width: "9%" },
        isCorrect: false,
      },
      {
        id: 12,
        src: zucchini,
        alt: "кабачок",
        position: { top: "47%", left: "69%" },
        style: { width: "8%" },
        isCorrect: false,
      },
    ],
  },
  {
    id: 4,
    background: wallperFruitVegetable,
    items: [
      {
        id: 1,
        src: pineapple,
        alt: "ананас",
        position: { top: "4%", left: "29%" },
        style: { width: "15%", minWidth: "180px" },
        isCorrect: true,
      },
      {
        id: 2,
        src: pomegranate,
        alt: "гранат",
        position: { top: "12%", left: "43%" },
        style: { width: "10%", minWidth: "112px" },
        isCorrect: false,
      },
      {
        id: 3,
        src: mandarin,
        alt: "мандарин",
        position: { top: "18%", left: "53%" },
        style: { width: "6%", minWidth: "90px" },
        isCorrect: true,
      },
      {
        id: 4,
        src: appleYellow,
        alt: "желтое яблоко",
        position: { top: "13%", left: "62%" },
        style: { width: "6%", minWidth: "1px" },
        isCorrect: false,
      },
      {
        id: 5,
        src: orange,
        alt: "апельсин",
        position: { top: "9%", left: "71%" },
        style: { width: "8%", minWidth: "100px" },
        isCorrect: true,
      },
      {
        id: 6,
        src: pomegranate,
        alt: "гранат",
        position: { top: "31%", left: "47%" },
        style: { width: "10%", minWidth: "100px" },
        isCorrect: false,
      },
      {
        id: 7,
        src: pear,
        alt: "груша",
        position: { top: "31%", left: "58%" },
        style: { width: "6%", minWidth: "70px" },
        isCorrect: false,
      },
      {
        id: 8,
        src: pomegranate,
        alt: "гранат",
        position: { top: "28%", left: "68%" },
        style: { width: "10%", minWidth: "100px" },
        isCorrect: false,
      },
      {
        id: 9,
        src: appleRed,
        alt: "красное яблоко",
        position: { top: "57%", left: "30%" },
        style: { width: "9%", minWidth: "100px" },
        isCorrect: false,
      },
      {
        id: 10,
        src: waterlemon,
        alt: "арбуз",
        position: { top: "71%", left: "57%" },
        style: { width: "11%", minWidth: "140px" },
        isCorrect: false,
      },
    ],
  },
  {
    id: 5,
    background: wallperSweets,
    items: [
      {
        id: 1,
        src: lemonade,
        alt: "лимонад",
        position: { top: "7%", left: "17%" },
        style: { width: "8%", minWidth: "100px" },
        isCorrect: true,
      },
      {
        id: 2,
        src: cookies,
        alt: "печенье",
        position: { top: "12%", left: "31%" },
        style: { width: "14%", minWidth: "168px" },
        isCorrect: true,
      },
      {
        id: 3,
        src: lollipops,
        alt: "конфеты",
        position: { top: "14%", left: "71%" },
        style: { width: "11%", minWidth: "140px" },
        isCorrect: false,
      },
      {
        id: 4,
        src: lollipops,
        alt: "конфеты",
        position: { top: "14%", left: "63%" },
        style: { width: "11%", minWidth: "140px" },
        isCorrect: false,
      },
      {
        id: 5,
        src: lollipops,
        alt: "конфеты",
        position: { top: "14%", left: "51%" },
        style: { width: "11%", minWidth: "140px" },
        isCorrect: false,
      },
      {
        id: 6,
        src: gingerbread,
        alt: "пряники",
        position: { top: "46%", left: "14%" },
        style: { width: "13%", minWidth: "160px" },
        isCorrect: false,
      },
      {
        id: 7,
        src: gingerbread,
        alt: "пряники",
        position: { top: "46%", left: "23%" },
        style: { width: "13%", minWidth: "160px" },
        isCorrect: false,
      },
      {
        id: 8,
        src: gingerbread,
        alt: "пряники",
        position: { top: "46%", left: "32%" },
        style: { width: "13%", minWidth: "160px" },
        isCorrect: false,
      },
      {
        id: 9,
        src: chocolate,
        alt: "шоколад",
        position: { top: "43%", left: "45%" },
        style: { width: "17%", minWidth: "210px" },
        isCorrect: true,
      },
      {
        id: 10,
        src: lollipops,
        alt: "конфеты",
        position: { top: "42%", left: "66%" },
        style: { width: "11%", minWidth: "140px" },
        isCorrect: false,
      },
      {
        id: 11,
        src: halva,
        alt: "халва",
        position: { top: "73%", left: "18%" },
        style: { width: "13%", minWidth: "90px" },
        isCorrect: false,
      },
      {
        id: 12,
        src: halva,
        alt: "халва",
        position: { top: "73%", left: "32%" },
        style: { width: "13%", minWidth: "90px" },
        isCorrect: false,
      },
      {
        id: 13,
        src: halva,
        alt: "халва",
        position: { top: "73%", left: "46%" },
        style: { width: "13%", minWidth: "90px" },
        isCorrect: false,
      },
      {
        id: 14,
        src: halva,
        alt: "халва",
        position: { top: "73%", left: "60%" },
        style: { width: "13%", minWidth: "90px" },
        isCorrect: false,
      },
    ],
  },
];

const ShoppingAheadSecondPart = ({
  onNextScene,
  audioRefs,
  playBackgroundMusic,
  setBackgroundMusicVolume,
}) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const currentTask = tasks[currentTaskIndex];
  const [remainingCorrectItems, setRemainingCorrectItems] = useState(0);
  const [removedItems, setRemovedItems] = useState({});

  const {
    breadSectionAudioRef,
    milkSectionAudioRef,
    fruitsSectionAudioRef,
    vegetablesSectionAudioRef,
    dessertSectionAudioRef,
    praiseSecondPartAudioRef,
    correctAnswerAudioRef,
    incorrectAnswerAudioRef,
  } = audioRefs;

  const audioRef = useRef(null);

  const taskAudioRefMap = {
    0: breadSectionAudioRef,
    1: milkSectionAudioRef,
    3: vegetablesSectionAudioRef,
    4: fruitsSectionAudioRef,
    5: dessertSectionAudioRef,
  };

  useEffect(() => {
    setIsInteractionBlocked(true);

    const currentTaskId = currentTask.id;
    const audioRefToPlay = taskAudioRefMap[currentTaskId];

    if (audioRefToPlay && audioRefToPlay.current) {
      audioRefToPlay.current.play().catch((error) => {
        setIsInteractionBlocked(false);
      });

      audioRefToPlay.current.onended = () => {
        setIsInteractionBlocked(false);
        playBackgroundMusic();
      };
    } else {
      setIsInteractionBlocked(false);
    }
  }, [currentTask]);

  useEffect(() => {
    const correctItemsCount = currentTask.items.filter(
      (item) => item.isCorrect
    ).length;
    setRemainingCorrectItems(correctItemsCount);
  }, [currentTask]);

  const handleItemClick = (item, event) => {
    if (isInteractionBlocked) return;

    setIsInteractionBlocked(true);

    const itemElement = event.target;

    if (item.isCorrect) {
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.currentTime = 0;
        correctAnswerAudioRef.current.play();
      }

      gsap.to(itemElement, {
        duration: 0.5,
        scale: 1.2,
        boxShadow: "0px 0px 20px 10px rgba(0, 255, 0, 0.5)",
        borderRadius: "50%",
        ease: "power1.out",
        onComplete: () => {
          gsap.to(itemElement, {
            duration: 0.5,
            opacity: 0,
            scale: 0.8,
            ease: "power1.in",
            onComplete: () => {
              // Обновляем removedItems с учетом текущей задачи
              setRemovedItems((prevRemovedItems) => {
                const taskId = currentTask.id;
                const updatedRemovedItemsForTask = [
                  ...(prevRemovedItems[taskId] || []),
                  item.id,
                ];
                return {
                  ...prevRemovedItems,
                  [taskId]: updatedRemovedItemsForTask,
                };
              });
              setIsInteractionBlocked(false);

              setRemainingCorrectItems((prevCount) => {
                const newCount = prevCount - 1;
                if (newCount === 0) {
                  if (currentTaskIndex < tasks.length - 1) {
                    setCurrentTaskIndex((prevIndex) => prevIndex + 1);
                  } else {
                    if (praiseSecondPartAudioRef.current) {
                      praiseSecondPartAudioRef.current.currentTime = 0;
                      praiseSecondPartAudioRef.current.play();
                    }
                    praiseSecondPartAudioRef.current.onended = () => {
                      onNextScene();
                    };
                  }
                }
                return newCount;
              });
            },
          });
        },
      });
    } else {
      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.currentTime = 0;
        incorrectAnswerAudioRef.current.play();
      }
      gsap.to(itemElement, {
        duration: 0.5,
        boxShadow: "0px 0px 20px 10px rgba(255, 0, 0, 0.5)",
        borderRadius: "50%",
        ease: "power1.out",
        onComplete: () => {
          gsap.to(itemElement, {
            duration: 1,
            boxShadow: "0px 0px 0px 0px rgba(255, 0, 0, 0)",
            ease: "power1.in",
            onComplete: () => {
              gsap.set(itemElement, { clearProps: "boxShadow,borderRadius" });
              setIsInteractionBlocked(false);
            },
          });
        },
      });
    }
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (breadSectionAudioRef.current) {
        breadSectionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (milkSectionAudioRef.current) {
        milkSectionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (fruitsSectionAudioRef.current) {
        fruitsSectionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (vegetablesSectionAudioRef.current) {
        vegetablesSectionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (dessertSectionAudioRef.current) {
        dessertSectionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (praiseSecondPartAudioRef.current) {
        praiseSecondPartAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      setBackgroundMusicVolume(newIsSoundOn ? 1 : 0);

      return newIsSoundOn;
    });
  };

  const handleRepeatTask = () => {
    const currentTaskId = currentTask.id;
    const audioRefToPlay = taskAudioRefMap[currentTaskId];

    if (audioRefToPlay && audioRefToPlay.current && !isInteractionBlocked) {
      setIsInteractionBlocked(true); // Блокируем повторное нажатие кнопки
      audioRefToPlay.current.currentTime = 0; // Перематываем трек на начало
      audioRefToPlay.current.play(); // Запускаем воспроизведение

      audioRefToPlay.current.onended = () => {
        setIsInteractionBlocked(false); // Разблокируем после завершения
      };
    }
  };

  return (
    <>
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.wallpaperWrapper}>
            {currentTask.background && (
              <img
                className={styles.wallper}
                src={currentTask.background}
                alt="обои"
              />
            )}
            <div className={styles.absoluteContainer}>
              {currentTask.items &&
                currentTask.items
                  .filter((item) => {
                    const taskRemovedItems = removedItems[currentTask.id] || [];
                    return !taskRemovedItems.includes(item.id);
                  })
                  .map((item) => (
                    <img
                      key={`${currentTask.id}-${item.id}`}
                      src={item.src}
                      alt={item.alt}
                      className={styles.item}
                      style={{
                        ...item.position,
                        ...item.style,
                        position: "absolute",
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleItemClick(item, e)}
                    />
                  ))}
              <div className={styles.buttonsContainer}>
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>

                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleRepeatTask}
                >
                  <img src={repeatTaskImg} alt="повтор звука" />
                  <span className={styles.tooltip}>
                    Нажмите для повторного прослушивания инструкции
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {/* {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )} */}
    </>
  );
};

export default ShoppingAheadSecondPart;
