import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";

import styles from "./talking-projector.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import apple from "./images/apple.png";
import banana from "./images/banana.png";
import bag from "./images/bag.png";
import beet from "./images/beet.png";
import boots from "./images/boots.png";
import bull from "./images/bull.png";
import cabbage from "./images/cabbage.png";
import car from "./images/car.png";
import carrot from "./images/carrot.png";
import cat from "./images/cat.png";
import cow from "./images/cow.png";
import eggplant from "./images/eggplant.png";
import fork from "./images/fork.png";
import hat from "./images/hat.png";
import hatTwo from "./images/hatTwo.png";
import horse from "./images/horse.png";
import jacket from "./images/jacket.png";
import jeans from "./images/jeans.png";
import knife from "./images/knife.png";
import mittens from "./images/mittens.png";
import onion from "./images/onion.png";
import pear from "./images/pear.png";
import plate from "./images/plate.png";
import plum from "./images/plum.png";
import potato from "./images/potato.png";
import projector from "./images/projector.png";
import scarf from "./images/scarf.png";
import shoes from "./images/shoes.png";
import shoesW from "./images/shoesW.png";
import shorts from "./images/shorts.png";
import skirt from "./images/skirt.png";
import spoon from "./images/spoon.png";
import swimsuit from "./images/swimsuit.png";
import tomato from "./images/tomato.png";
import train from "./images/train.png";
import tram from "./images/tram.png";
import trolleybus from "./images/trolleybus.png";
import wolf from "./images/wolf.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import secondInstructionSound from "./sounds/secondInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import correctAnswerSound from "./sounds/rightAnswer.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import praiseSound from "./sounds/praise.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskOneSound from "./sounds/taskOne.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const tasks = [
  {
    images: [
      { src: apple, alt: "apple" },
      { src: pear, alt: "pear" },
      { src: banana, alt: "banana" },
      { src: potato, alt: "potato" },
    ],
    correctAnswer: "potato",
  },
  {
    images: [
      { src: onion, alt: "onion" },
      { src: beet, alt: "beet" },
      { src: carrot, alt: "carrot" },
      { src: tomato, alt: "tomato" },
    ],
    correctAnswer: "tomato",
  },
  {
    images: [
      { src: tomato, alt: "tomato" },
      { src: plum, alt: "plum" },
      { src: cabbage, alt: "cabbage" },
      { src: eggplant, alt: "eggplant" },
    ],
    correctAnswer: "plum",
  },
  {
    images: [
      { src: tram, alt: "tram" },
      { src: trolleybus, alt: "trolleybus" },
      { src: car, alt: "car" },
      { src: train, alt: "train" },
    ],
    correctAnswer: "car",
  },
  {
    images: [
      { src: cow, alt: "cow" },
      { src: wolf, alt: "wolf" },
      { src: bull, alt: "bull" },
      { src: horse, alt: "horse" },
    ],
    correctAnswer: "wolf",
  },
  {
    images: [
      { src: hat, alt: "hat" },
      { src: skirt, alt: "skirt" },
      { src: jeans, alt: "jeans" },
      { src: shorts, alt: "shorts" },
    ],
    correctAnswer: "hat",
  },

  {
    images: [
      { src: knife, alt: "knife" },
      { src: plate, alt: "plate" },
      { src: fork, alt: "fork" },
      { src: spoon, alt: "spoon" },
    ],
    correctAnswer: "plate",
  },

  {
    images: [
      { src: shoes, alt: "shoes" },
      { src: boots, alt: "boots" },
      { src: shoesW, alt: "shoesW" },
      { src: bag, alt: "bag" },
    ],
    correctAnswer: "bag",
  },

  {
    images: [
      { src: hatTwo, alt: "hatTwo" },
      { src: mittens, alt: "mittens" },
      { src: cat, alt: "cat" },
      { src: swimsuit, alt: "swimsuit" },
    ],
    correctAnswer: "mittens",
  },
  {
    images: [
      { src: mittens, alt: "mittens" },
      { src: swimsuit, alt: "swimsuit" },
      { src: jacket, alt: "jacket" },
      { src: scarf, alt: "scarf" },
    ],
    correctAnswer: "swimsuit",
  },
];

const TalkingProjectorGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);

  const currentTask = tasks[currentTaskIndex];

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const secondInstructionAudioRef = useRef(new Audio(secondInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const correctAnswerSoundRef = useRef(new Audio(correctAnswerSound));
  const incorrectAnswerSoundRef = useRef(new Audio(incorrectAnswerSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const taskSoundsRefs = useRef([
    new Audio(taskZeroSound),
    new Audio(taskOneSound),
    new Audio(taskTwoSound),
    new Audio(taskThreeSound),
    new Audio(taskFourSound),
    new Audio(taskFiveSound),
    new Audio(taskZeroSound),
    new Audio(taskOneSound),
    new Audio(taskThreeSound),
    new Audio(taskFourSound),
    new Audio(taskFiveSound),
  ]);

  const handleImageClick = (selectedImageAlt) => {
    if (isInteractionBlocked) return;

    setIsInteractionBlocked(true);
    setSelectedAnswer(selectedImageAlt);

    if (selectedImageAlt === currentTask.correctAnswer) {
      // Правильный ответ
      setIsAnswerCorrect(true);

      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.play();
      }

      const taskAudio = taskSoundsRefs.current[currentTaskIndex];
      if (taskAudio) {
        taskAudio.play();
      }

      // Переходим к следующему заданию через 5 секунд
      setTimeout(() => {
        if (currentTaskIndex < tasks.length - 1) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
          setSelectedAnswer(null);
          setIsAnswerCorrect(null);
          setIsInteractionBlocked(false);
        } else {
          if (praiseSoundRef.current) {
            praiseSoundRef.current.play();
          }
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 3000);
        }
      }, 2000);
    } else {
      // Неправильный ответ
      setIsAnswerCorrect(false);
      if (incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.play();
      }

      // Убираем красную тень через 3 секунды
      setTimeout(() => {
        setSelectedAnswer(null);
        setIsAnswerCorrect(null);
        setIsInteractionBlocked(false);
      }, 3000);
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        if (secondInstructionAudioRef.current) {
          secondInstructionAudioRef.current.play();
        }
        secondInstructionAudioRef.current.onended = () => {
          setIsInteractionBlocked(false);
          if (backgroundAudioRef.current) {
            backgroundAudioRef.current.loop = true;
            backgroundAudioRef.current.play();
          }
        };
      };
    }

    setIsButtonVisible(false);
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (secondInstructionAudioRef.current) {
        secondInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (taskSoundsRefs.current) {
        taskSoundsRefs.current.forEach((audio) => {
          if (audio) {
            audio.volume = newIsSoundOn ? 1 : 0;
          }
        });
      }

      return newIsSoundOn;
    });
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";

    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (secondInstructionAudioRef.current) {
        secondInstructionAudioRef.current.pause();
        secondInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.pause();
        correctAnswerSoundRef.current.currentTime = 0;
      }

      if (incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.pause();
        incorrectAnswerSoundRef.current.currentTime = 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }

      if (taskSoundsRefs.current) {
        taskSoundsRefs.current.forEach((audio) => {
          if (audio) {
            audio.pause();
            audio.currentTime = 0;
          }
        });
      }

      document.body.style.backgroundColor = null;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}

      <div className={`container ${styles.someOtherClass}`}>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div className={styles.backgroundContainer}>
          <div
            className={styles.imagesContainer}
            style={{
              pointerEvents: isInteractionBlocked ? "none" : "auto",
            }}
          >
            {currentTask.images.map((image) => (
              <div
                key={image.alt}
                className={`${styles.imageWrapper} ${
                  selectedAnswer === image.alt && isAnswerCorrect === false
                    ? styles.incorrect
                    : selectedAnswer === image.alt && isAnswerCorrect === true
                    ? styles.correct
                    : ""
                }`}
                onClick={() => handleImageClick(image.alt)}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className={styles.taskImage}
                />
              </div>
            ))}
            <img
              src={projector}
              alt="Проектор"
              className={styles.projectorImage}
            />
          </div>

          <div className={styles.fireworksContainer}></div>
        </div>
        <div className={styles.absoluteContainer}>
          <div className={styles.buttonsContainer}>
            <button
              className={styles.buttonsRepeatAndSoundOnOff}
              onClick={handleSoundOnOff}
            >
              <img src={isSoundOn ? dynamicImg : dynamicOff} alt="динамик" />
              <span className={styles.tooltip}>Включить / выключить звук</span>
            </button>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default TalkingProjectorGame;
