import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import EndGameOptions from "../../end-game-options/end-game-options";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import styles from "./lost-things.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import repeatTaskImg from "./images/repeatTask.png";
import wallperMain from "./images/wallperMain.png";
import wallperWarehouse from "./images/wallperWarehouse.png";
import operator from "./images/operator.png";
import operatorOnCall from "./images/operatorOnCall.png";
import carLoader from "./images/carLoader.png";
import elementLoader from "./images/elementLoader.png";
import ball from "./images/ball.png";
import globe from "./images/globe.png";
import scarf from "./images/scarf.png";
import umbrella from "./images/umbrella.png";
import watch from "./images/watch.png";
import shovel from "./images/shovel.png";
import bucket from "./images/bucket.png";
import smallCar from "./images/smallCar.png";
import smallTrailer from "./images/smallTrailer.png";
import towel from "./images/towel.png";
import hook from "./images/hook.png";
import hammer from "./images/hammer.png";
import cane from "./images/cane.png";
import horns from "./images/horns.png";
import tv from "./images/tv.png";
import bag from "./images/bag.png";
import ballBW from "./images/ballBW.png";
import smartphone from "./images/smarphone.png";
import bar from "./images/bar.png";
import baloon from "./images/baloon.png";
import pack from "./images/package.png";
import unicronBag from "./images/unicornBag.png";
import womenBag from "./images/womenBag.png";
import armbands from "./images/armbands.png";
import baloonTwo from "./images/baloonTwo.png";
import circleForSwimming from "./images/circleForSwimming.png";
import mattress from "./images/mattress.png";
import magGlass from "./images/magGlass.png";
import notebook from "./images/notebook.png";
import sunglasses from "./images/sunglasses.png";
import telescope from "./images/telescope.png";
import ticket from "./images/ticket.png";
import shoe from "./images/shoe.png";
import boots from "./images/boots.png";
import jacket from "./images/jacket.png";
import mittens from "./images/mittens.png";
import sneakers from "./images/sneakers.png";
import blanket from "./images/blanket.png";
import napkins from "./images/napkins.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import carSound from "./sounds/carSound.mp3";
import objectHideSound from "./sounds/objectHideSound.mp3";
import phoneCallSound from "./sounds/phoneCallSound.mp3";
import correctAnswerSound from "./sounds/yes.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import zeroTaskSoundA from "./sounds/zeroTaskSoundA.mp3";
import zeroTaskSoundB from "./sounds/zeroTaskSoundB.mp3";
import oneTaskSoundA from "./sounds/oneTaskSoundA.mp3";
import oneTaskSoundB from "./sounds/oneTaskSoundB.mp3";
import twoTaskSoundA from "./sounds/twoTaskSoundA.mp3";
import twoTaskSoundB from "./sounds/twoTaskSoundB.mp3";
import threeTaskSoundA from "./sounds/threeTaskSoundA.mp3";
import threeTaskSoundB from "./sounds/threeTaskSoundB.mp3";
import fourTaskSoundA from "./sounds/fourTaskSoundA.mp3";
import fourTaskSoundB from "./sounds/fourTaskSoundB.mp3";
import sixTaskSoundA from "./sounds/sixTaskSoundA.mp3";
import sixTaskSoundB from "./sounds/sixTaskSoundB.mp3";
import sevenTaskSoundA from "./sounds/sevenTaskSoundA.mp3";
import sevenTaskSoundB from "./sounds/sevenTaskSoundB.mp3";
import eightTaskSoundA from "./sounds/eightTaskSoundA.mp3";
import eightTaskSoundB from "./sounds/eightTaskSoundB.mp3";
import nineTaskSoundA from "./sounds/nineTaskSoundA.mp3";
import nineTaskSoundB from "./sounds/nineTaskSoundB.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const LostThingsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [hasCurrentTaskAudioPlayed, setHasCurrentTaskAudioPlayed] =
    useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [currentScene, setCurrentScene] = useState("operator");
  const [isOperatorOnCall, setIsOperatorOnCall] = useState(false);
  const [isOperatorFinishedTalking, setIsOperatorFinishedTalking] =
    useState(false);
  const [foundItem, setFoundItem] = useState(false);
  const [itemOnLoader, setItemOnLoader] = useState(false);
  const [correctItem, setCorrectItem] = useState(null);

  gsap.registerPlugin(MotionPathPlugin);
  const operatorRef = useRef(null);
  const operatorCarLoaderRef = useRef(null);
  const operatorPlatformRef = useRef(null);
  const warehouseCarLoaderRef = useRef(null);
  const warehousePlatformRef = useRef(null);
  const operatorItemRef = useRef(null);
  const itemRef = useRef({});

  const warehouseLoaderPositionX = window.innerWidth / 2 - 100;
  const offScreenRightX = window.innerWidth;
  const offScreenLeftX = -350;
  const operatorLoaderStartX = 100;
  const operatorLoaderFinishX = 10;

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const carAudioRef = useRef(new Audio(carSound));
  const objectHideAudioRef = useRef(new Audio(objectHideSound));
  const phoneCallAudioRef = useRef(new Audio(phoneCallSound));
  const correctAnswerAudioRef = useRef(new Audio(correctAnswerSound));
  const incorrectAnsweAudioRef = useRef(new Audio(incorrectAnswerSound));
  const praiseAudioRef = useRef(new Audio(praiseSound));
  const ZeroTaskSoundAAudioRef = useRef(new Audio(zeroTaskSoundA));
  const ZeroTaskSoundBAudioRef = useRef(new Audio(zeroTaskSoundB));
  const oneTaskSoundAAudioRef = useRef(new Audio(oneTaskSoundA));
  const oneTaskSoundBAudioRef = useRef(new Audio(oneTaskSoundB));
  const twoTaskSoundAAudioRef = useRef(new Audio(twoTaskSoundA));
  const twoTaskSoundBAudioRef = useRef(new Audio(twoTaskSoundB));
  const threeTaskSoundAAudioRef = useRef(new Audio(threeTaskSoundA));
  const threeTaskSoundBAudioRef = useRef(new Audio(threeTaskSoundB));
  const fourTaskSoundAAudioRef = useRef(new Audio(fourTaskSoundA));
  const fourTaskSoundBAudioRef = useRef(new Audio(fourTaskSoundB));
  const sixTaskSoundAAudioRef = useRef(new Audio(sixTaskSoundA));
  const sixTaskSoundBAudioRef = useRef(new Audio(sixTaskSoundB));
  const sevenTaskSoundAAudioRef = useRef(new Audio(sevenTaskSoundA));
  const sevenTaskSoundBAudioRef = useRef(new Audio(sevenTaskSoundB));
  const eightTaskSoundAAudioRef = useRef(new Audio(eightTaskSoundA));
  const eightTaskSoundBAudioRef = useRef(new Audio(eightTaskSoundB));
  const nineTaskSoundAAudioRef = useRef(new Audio(nineTaskSoundA));
  const nineTaskSoundBAudioRef = useRef(new Audio(nineTaskSoundB));

  const tasks = [
    {
      id: 1,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: ball,
          alt: "мяч",
          position: { top: "33%", left: "42%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 2,
          src: globe,
          alt: "глобус",
          position: { top: "55%", left: "14%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: scarf,
          alt: "шарф",
          position: { top: "-1%", left: "53%" },
          style: { width: "9%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 4,
          src: umbrella,
          alt: "зонт",
          position: { top: "46%", left: "35%" },
          style: { width: "20%", minWidth: "280px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: watch,
          alt: "часы",
          position: { top: "42%", left: "27%" },
          style: { width: "8%", minWidth: "110px" },
          isCorrect: true,
        },
      ],
      audioRefA: ZeroTaskSoundAAudioRef,
      audioRefB: ZeroTaskSoundBAudioRef,
    },
    {
      id: 2,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: towel,
          alt: "полотенце",
          position: { top: "35%", left: "42%" },
          style: { width: "11%", minWidth: "136px" },
          isCorrect: false,
        },
        {
          id: 2,
          src: smallCar,
          alt: "машинка",
          position: { top: "66%", left: "14%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: smallTrailer,
          alt: "грузовик",
          position: { top: "62%", left: "30%" },
          style: { width: "9%", minWidth: "120px" },
          isCorrect: true,
        },
        {
          id: 4,
          src: bucket,
          alt: "ведро",
          position: { top: "55%", left: "40%" },
          style: { width: "12%", minWidth: "170px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: shovel,
          alt: "лопата",
          position: { top: "36%", left: "12%" },
          style: { width: "8%", minWidth: "114px" },
          isCorrect: false,
        },
      ],
      audioRefA: oneTaskSoundAAudioRef,
      audioRefB: oneTaskSoundBAudioRef,
    },
    {
      id: 3,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: umbrella,
          alt: "зонт",
          position: { top: "46%", left: "35%" },
          style: { width: "20%", minWidth: "280px" },
          isCorrect: true,
          extraClass: styles.ubmbrellaItem,
        },
        {
          id: 2,
          src: hook,
          alt: "крючок",
          position: { top: "35%", left: "14%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: hammer,
          alt: "молоток",
          position: { top: "33.5%", left: "30%" },
          style: { width: "11%", minWidth: "180px" },
          isCorrect: false,
        },
        {
          id: 4,
          src: cane,
          alt: "трость",
          position: { top: "55%", left: "13%" },
          style: { width: "12%", minWidth: "170px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: horns,
          alt: "рога",
          position: { top: "2%", left: "12%" },
          style: { width: "17%", minWidth: "114px" },
          isCorrect: false,
        },
      ],
      audioRefA: twoTaskSoundAAudioRef,
      audioRefB: twoTaskSoundBAudioRef,
    },
    {
      id: 4,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: tv,
          alt: "телевизор",
          position: { top: "50%", left: "35%" },
          style: { width: "13%", minWidth: "180px" },
          isCorrect: true,
          extraClass: styles.tvItem,
        },
        {
          id: 2,
          src: bag,
          alt: "рюкзак",
          position: { top: "24%", left: "14%" },
          style: { width: "13%", minWidth: "210px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: ballBW,
          alt: "мячик",
          position: { top: "32%", left: "38%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 4,
          src: smartphone,
          alt: "телефон",
          position: { top: "55%", left: "13%" },
          style: { width: "10%", minWidth: "170px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: bar,
          alt: "брусок",
          position: { top: "8%", left: "14%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: false,
        },
      ],
      audioRefA: threeTaskSoundAAudioRef,
      audioRefB: threeTaskSoundBAudioRef,
    },
    {
      id: 5,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: unicronBag,
          alt: "сумка с единорогом",
          position: { top: "2%", left: "30%" },
          style: { width: "10%", minWidth: "155px" },
          isCorrect: true,
        },
        {
          id: 2,
          src: bag,
          alt: "рюкзак",
          position: { top: "24%", left: "14%" },
          style: { width: "13%", minWidth: "210px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: baloon,
          alt: "воздушный шарик",
          position: { top: "28%", left: "38%" },
          style: { width: "10%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 4,
          src: womenBag,
          alt: "женская сумка",
          position: { top: "55%", left: "13%" },
          style: { width: "10%", minWidth: "170px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: pack,
          alt: "пакет из магазина",
          position: { top: "55%", left: "35%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: false,
        },
      ],
      audioRefA: fourTaskSoundAAudioRef,
      audioRefB: fourTaskSoundBAudioRef,
    },
    {
      id: 6,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: armbands,
          alt: "нарукавники",
          position: { top: "36%", left: "30%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 2,
          src: ball,
          alt: "мяч",
          position: { top: "32%", left: "18%" },
          style: { width: "8%", minWidth: "130px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: baloonTwo,
          alt: "воздушный шарик",
          position: { top: "3%", left: "38%" },
          style: { width: "10%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 4,
          src: mattress,
          alt: "матрас",
          position: { top: "55%", left: "34%" },
          style: { width: "15%", minWidth: "200px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: circleForSwimming,
          alt: "надувной круг",
          position: { top: "56%", left: "13%" },
          style: { width: "10%", minWidth: "150px" },
          isCorrect: true,
        },
      ],
      audioRefA: sixTaskSoundAAudioRef,
      audioRefB: sixTaskSoundBAudioRef,
    },
    {
      id: 7,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: sunglasses,
          alt: "очки",
          position: { top: "40%", left: "38%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: true,
        },
        {
          id: 2,
          src: notebook,
          alt: "тетрадь",
          position: { top: "35%", left: "18%" },
          style: { width: "8%", minWidth: "130px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: telescope,
          alt: "подзорная труба",
          position: { top: "3%", left: "33%" },
          style: { width: "15%", minWidth: "210px" },
          isCorrect: false,
        },
        {
          id: 4,
          src: ticket,
          alt: "билет",
          position: { top: "55%", left: "34%" },
          style: { width: "15%", minWidth: "200px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: magGlass,
          alt: "лупа",
          position: { top: "58%", left: "13%" },
          style: { width: "10%", minWidth: "150px" },
          isCorrect: false,
        },
      ],
      audioRefA: sevenTaskSoundAAudioRef,
      audioRefB: sevenTaskSoundBAudioRef,
    },
    {
      id: 8,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: shoe,
          alt: "туфелька",
          position: { top: "60%", left: "30%" },
          style: { width: "8%", minWidth: "120px" },
          isCorrect: true,
        },
        {
          id: 2,
          src: jacket,
          alt: "куртка",
          position: { top: "20%", left: "18%" },
          style: { width: "15%", minWidth: "200px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: boots,
          alt: "сапоги",
          position: { top: "3%", left: "33%" },
          style: { width: "10%", minWidth: "150px" },
          isCorrect: false,
        },
        {
          id: 4,
          src: mittens,
          alt: "варежки",
          position: { top: "60%", left: "40%" },
          style: { width: "8%", minWidth: "100px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: sneakers,
          alt: "кроссовки",
          position: { top: "62%", left: "13%" },
          style: { width: "8%", minWidth: "130px" },
          isCorrect: false,
        },
      ],
      audioRefA: eightTaskSoundAAudioRef,
      audioRefB: eightTaskSoundBAudioRef,
    },
    {
      id: 9,
      warehouseBackground: wallperWarehouse,
      items: [
        {
          id: 1,
          src: blanket,
          alt: "одеяло",
          position: { top: "46%", left: "32%" },
          style: { width: "20%", minWidth: "280px" },
          isCorrect: true,
          extraClass: styles.blanketItem,
        },
        {
          id: 2,
          src: towel,
          alt: "полотенце",
          position: { top: "34%", left: "15%" },
          style: { width: "13%", minWidth: "180px" },
          isCorrect: false,
        },
        {
          id: 3,
          src: scarf,
          alt: "шарф",
          position: { top: "-1%", left: "53%" },
          style: { width: "9%", minWidth: "120px" },
          isCorrect: false,
        },
        {
          id: 4,
          src: napkins,
          alt: "салфетки",
          position: { top: "35%", left: "40%" },
          style: { width: "8%", minWidth: "130px" },
          isCorrect: false,
        },
        {
          id: 5,
          src: cane,
          alt: "трость",
          position: { top: "62%", left: "13%" },
          style: { width: "10%", minWidth: "130px" },
          isCorrect: false,
        },
      ],
      audioRefA: nineTaskSoundAAudioRef,
      audioRefB: nineTaskSoundBAudioRef,
    },
  ];

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        if (phoneCallAudioRef.current) {
          phoneCallAudioRef.current.loop = true;
          phoneCallAudioRef.current.play();
        }
        setIsInteractionBlocked(false);
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    if (currentScene === "operator") {
      gsap.set(operatorCarLoaderRef.current, {
        x: operatorLoaderStartX,
        scaleX: 1,
      });
    }
  }, [currentScene]);

  useEffect(() => {
    if (currentScene === "warehouse") {
      setIsInteractionBlocked(true);
      gsap.set(warehouseCarLoaderRef.current, {
        x: offScreenRightX,
        scaleX: 1,
      });
      gsap.to(warehouseCarLoaderRef.current, {
        x: warehouseLoaderPositionX,
        duration: 2,
        ease: "power2.inOut",
        onComplete: () => {
          setIsInteractionBlocked(false);
        },
      });
    }
  }, [currentScene]);

  useEffect(() => {
    if (currentScene === "operatorReturn") {
      gsap.set(operatorCarLoaderRef.current, {
        x: offScreenLeftX,
      });
      gsap.to(operatorCarLoaderRef.current, {
        x: operatorLoaderFinishX,
        duration: 2,
        ease: "power1.inOut",
        onComplete: () => {
          if (objectHideAudioRef.current) {
            objectHideAudioRef.current.play();
          }
          // "Освобождаем" предмет
          gsap.to(operatorItemRef.current, {
            y: -100, // Анимация подъема предмета
            opacity: 0,
            duration: 1,
            onComplete: () => {
              // Скрываем предмет
              setFoundItem(false);
              // Подготовка к следующему заданию
              prepareNextTask();
            },
          });
        },
      });
    }
  }, [currentScene]);

  useEffect(() => {
    setHasCurrentTaskAudioPlayed(false);
  }, [currentTaskIndex]);

  const handleOperatorClick = () => {
    if (isOperatorOnCall || isInteractionBlocked) return;

    setIsOperatorOnCall(true);
    setIsInteractionBlocked(true);

    // Останавливаем телефонный звонок
    if (phoneCallAudioRef.current) {
      phoneCallAudioRef.current.pause();
      phoneCallAudioRef.current.currentTime = 0;
      phoneCallAudioRef.current.loop = false;
    }

    const currentTask = tasks[currentTaskIndex];

    // Проигрываем аудио A
    if (currentTask.audioRefA && currentTask.audioRefA.current) {
      currentTask.audioRefA.current.play();

      currentTask.audioRefA.current.onended = () => {
        // Проигрываем аудио B
        if (currentTask.audioRefB && currentTask.audioRefB.current) {
          currentTask.audioRefB.current.play();

          currentTask.audioRefB.current.onended = () => {
            setHasCurrentTaskAudioPlayed(true);
            // Разблокируем взаимодействие после окончания обоих аудио
            setIsInteractionBlocked(false);
            setIsOperatorFinishedTalking(true);

            // Запускаем фоновую музыку
            if (backgroundAudioRef.current) {
              backgroundAudioRef.current.loop = true;
              backgroundAudioRef.current.play();
            }
          };
        } else {
          // Если аудио B нет
          setIsInteractionBlocked(false);
          setIsOperatorFinishedTalking(true);

          if (backgroundAudioRef.current) {
            backgroundAudioRef.current.loop = true;
            backgroundAudioRef.current.play();
          }
        }
      };
    } else {
      // Если аудио A нет
      setIsInteractionBlocked(false);
      setIsOperatorFinishedTalking(true);

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.loop = true;
        backgroundAudioRef.current.play();
      }
    }
  };

  const handleLoaderClick = () => {
    if (!isOperatorFinishedTalking || isInteractionBlocked) return;

    if (carAudioRef.current) {
      carAudioRef.current.play();
    }

    gsap.to(operatorCarLoaderRef.current, {
      x: -500,
      duration: 2,
      ease: "power1.inOut",
      onComplete: () => {
        setCurrentScene("warehouse");
        setIsOperatorFinishedTalking(false);
      },
    });
  };

  const handleItemClick = (item) => {
    if (isInteractionBlocked || foundItem) return;

    if (item.isCorrect) {
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.play();
      }

      setFoundItem(true);
      setCorrectItem(item);

      const itemElement = itemRef.current[item.id];
      const platformElement = warehousePlatformRef.current;

      // Получаем позиции предмета и платформы
      const itemRect = itemElement.getBoundingClientRect();
      const platformRect = platformElement.getBoundingClientRect();

      // Вычисляем смещение для перемещения предмета к центру платформы
      const deltaX =
        platformRect.left +
        platformRect.width / 2 -
        (itemRect.left + itemRect.width / 2);
      const deltaY =
        platformRect.top +
        platformRect.height / 2 -
        (itemRect.top + itemRect.height / 2);

      // Анимируем перемещение предмета к платформе
      gsap.to(itemElement, {
        duration: 1,
        x: `+=${deltaX}`,
        y: `+=${deltaY}`,
        ease: "power1.inOut",
        onComplete: () => {
          // Получаем позицию предмета относительно окна
          // Получаем текущие позиции предмета и погрузчика
          const itemX = gsap.getProperty(itemElement, "x");
          const itemY = gsap.getProperty(itemElement, "y");
          const loaderX = gsap.getProperty(warehouseCarLoaderRef.current, "x");
          const loaderY = gsap.getProperty(warehouseCarLoaderRef.current, "y");

          // Вычисляем смещение предмета относительно погрузчика
          const offsetX = itemX - loaderX;
          const offsetY = itemY - loaderY;

          // Сохраняем смещение в data-атрибутах элемента
          itemElement.dataset.offsetX = offsetX;
          itemElement.dataset.offsetY = offsetY;
          animateWarehouseCarExitWithItem(itemElement);
        },
      });
    } else {
      // Анимация тряски при неправильном выборе
      if (incorrectAnsweAudioRef.current) {
        incorrectAnsweAudioRef.current.currentTime = 0;
        incorrectAnsweAudioRef.current.play();
      }
      gsap.to(itemRef.current[item.id], {
        x: "+=20",
        duration: 0.1,
        yoyo: true,
        repeat: 5,
        ease: "power1.inOut",
      });
    }
  };
  const animateWarehouseCarExitWithItem = (itemElement) => {
    const offsetX = parseFloat(itemElement.dataset.offsetX);
    const offsetY = parseFloat(itemElement.dataset.offsetY);

    if (carAudioRef.current) {
      carAudioRef.current.currentTime = 0;
      carAudioRef.current.play();
    }

    const timeline = gsap.timeline({
      onComplete: () => {
        setCurrentScene("operatorReturn");
      },
    });

    // Анимируем погрузчик
    timeline.to(warehouseCarLoaderRef.current, {
      x: offScreenRightX,
      duration: 2,
      ease: "power2.inOut",
      onUpdate: () => {
        // Обновляем позицию предмета при каждом кадре анимации
        const loaderX = gsap.getProperty(warehouseCarLoaderRef.current, "x");
        const loaderY = gsap.getProperty(warehouseCarLoaderRef.current, "y");

        gsap.set(itemElement, {
          x: loaderX + offsetX,
          y: loaderY + offsetY,
        });
      },
    });
  };

  const prepareNextTask = () => {
    setFoundItem(false);

    if (currentTaskIndex < tasks.length - 1) {
      setCurrentTaskIndex((prev) => prev + 1);
      setIsOperatorOnCall(false);
      setIsOperatorFinishedTalking(false);
      setCurrentScene("operator");

      // Запускаем телефонный звонок для новой задачи
      if (phoneCallAudioRef.current) {
        phoneCallAudioRef.current.loop = true;
        phoneCallAudioRef.current.play();
      }
    } else {
      if (praiseAudioRef.current) {
        praiseAudioRef.current.play();
      }

      triggerFireworks();

      setTimeout(() => {
        setIsGameCompleted(true);
      }, 3000);
    }
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      const audioRefs = [
        startInstructionAudioRef,
        backgroundAudioRef,
        carAudioRef,
        objectHideAudioRef,
        phoneCallAudioRef,
        correctAnswerAudioRef,
        incorrectAnsweAudioRef,
        praiseAudioRef,
        ZeroTaskSoundAAudioRef,
        ZeroTaskSoundBAudioRef,
        oneTaskSoundAAudioRef,
        oneTaskSoundBAudioRef,
        twoTaskSoundAAudioRef,
        twoTaskSoundBAudioRef,
        threeTaskSoundAAudioRef,
        threeTaskSoundBAudioRef,
        fourTaskSoundAAudioRef,
        fourTaskSoundBAudioRef,
        sixTaskSoundAAudioRef,
        sixTaskSoundBAudioRef,
        sevenTaskSoundAAudioRef,
        sevenTaskSoundBAudioRef,
        eightTaskSoundAAudioRef,
        eightTaskSoundBAudioRef,
        nineTaskSoundAAudioRef,
        nineTaskSoundBAudioRef,
      ];

      audioRefs.forEach((audioRef) => {
        if (audioRef.current) {
          audioRef.current.volume = newIsSoundOn ? 1 : 0;
        }
      });

      return newIsSoundOn;
    });
  };

  const handleRepeatWarehouseTask = () => {
    if (isInteractionBlocked) return;

    if (!hasCurrentTaskAudioPlayed) return;

    if (isAudioPlaying) return;

    const currentTask = tasks[currentTaskIndex];

    if (currentTask.audioRefA && currentTask.audioRefA.current) {
      if (!currentTask.audioRefA.current.paused) {
        return;
      }

      setIsInteractionBlocked(true);
      setIsAudioPlaying(true);

      currentTask.audioRefA.current.currentTime = 0;
      currentTask.audioRefA.current.play();

      currentTask.audioRefA.current.onended = () => {
        setIsInteractionBlocked(false);
        setIsAudioPlaying(false);
      };
    } else {
      setIsInteractionBlocked(false);
      setIsAudioPlaying(false);
    }
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;

      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      carAudioRef.current.pause();
      carAudioRef.current.currentTime = 0;

      objectHideAudioRef.current.pause();
      objectHideAudioRef.current.currentTime = 0;

      phoneCallAudioRef.current.pause();
      phoneCallAudioRef.current.currentTime = 0;

      correctAnswerAudioRef.current.pause();
      correctAnswerAudioRef.current.currentTime = 0;

      incorrectAnsweAudioRef.current.pause();
      incorrectAnsweAudioRef.current.currentTime = 0;

      praiseAudioRef.current.pause();
      praiseAudioRef.current.currentTime = 0;

      ZeroTaskSoundAAudioRef.current.pause();
      ZeroTaskSoundAAudioRef.current.currentTime = 0;

      ZeroTaskSoundBAudioRef.current.pause();
      ZeroTaskSoundBAudioRef.current.currentTime = 0;

      oneTaskSoundAAudioRef.current.pause();
      oneTaskSoundAAudioRef.current.currentTime = 0;

      oneTaskSoundBAudioRef.current.pause();
      oneTaskSoundBAudioRef.current.currentTime = 0;

      twoTaskSoundAAudioRef.current.pause();
      twoTaskSoundAAudioRef.current.currentTime = 0;

      twoTaskSoundBAudioRef.current.pause();
      twoTaskSoundBAudioRef.current.currentTime = 0;

      threeTaskSoundAAudioRef.current.pause();
      threeTaskSoundAAudioRef.current.currentTime = 0;

      threeTaskSoundBAudioRef.current.pause();
      threeTaskSoundBAudioRef.current.currentTime = 0;

      fourTaskSoundAAudioRef.current.pause();
      fourTaskSoundAAudioRef.current.currentTime = 0;

      fourTaskSoundBAudioRef.current.pause();
      fourTaskSoundBAudioRef.current.currentTime = 0;

      sixTaskSoundAAudioRef.current.pause();
      sixTaskSoundAAudioRef.current.currentTime = 0;

      sixTaskSoundBAudioRef.current.pause();
      sixTaskSoundBAudioRef.current.currentTime = 0;

      sevenTaskSoundAAudioRef.current.pause();
      sevenTaskSoundAAudioRef.current.currentTime = 0;

      sevenTaskSoundBAudioRef.current.pause();
      sevenTaskSoundBAudioRef.current.currentTime = 0;

      eightTaskSoundAAudioRef.current.pause();
      eightTaskSoundAAudioRef.current.currentTime = 0;

      eightTaskSoundBAudioRef.current.pause();
      eightTaskSoundBAudioRef.current.currentTime = 0;

      nineTaskSoundAAudioRef.current.pause();
      nineTaskSoundAAudioRef.current.currentTime = 0;

      nineTaskSoundBAudioRef.current.pause();
      nineTaskSoundBAudioRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          {currentScene === "operator" && (
            <div className={styles.wallpaperWrapper}>
              <img className={styles.wallper} src={wallperMain} alt="фон" />
              <div className={styles.absoluteContainer}>
                {/* Operator */}
                <img
                  src={isOperatorOnCall ? operatorOnCall : operator}
                  alt="оператор"
                  className={styles.operator}
                  ref={operatorRef}
                  onClick={handleOperatorClick}
                />
                {/* Loader */}
                <div
                  className={styles.loaderContainer}
                  ref={operatorCarLoaderRef}
                  onClick={handleLoaderClick}
                >
                  <img
                    src={carLoader}
                    alt="погрузчик"
                    className={styles.carLoader}
                  />
                  <img
                    src={elementLoader}
                    alt="элемент"
                    className={styles.elementLoader}
                    ref={operatorPlatformRef}
                  />
                </div>
              </div>
            </div>
          )}

          {currentScene === "warehouse" && (
            <div className={styles.warehouseScene}>
              <img
                className={styles.wallper}
                src={tasks[currentTaskIndex].warehouseBackground}
                alt="склад"
              />
              <div className={styles.itemsContainer}>
                {tasks[currentTaskIndex].items.map((item) => (
                  <img
                    key={item.id}
                    src={item.src}
                    alt={item.alt}
                    style={{
                      position: "absolute",
                      ...item.position,
                      ...item.style,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={() => handleItemClick(item)}
                    ref={(el) => (itemRef.current[item.id] = el)}
                  />
                ))}
              </div>
              {/* Loader in warehouse */}
              <div
                className={styles.loaderContainer}
                ref={warehouseCarLoaderRef}
              >
                <img
                  src={carLoader}
                  alt="погрузчик"
                  className={styles.carLoader}
                />
                <img
                  src={elementLoader}
                  alt="элемент"
                  className={styles.elementLoader}
                  ref={warehousePlatformRef}
                />
              </div>
            </div>
          )}

          {currentScene === "operatorReturn" && (
            <div className={styles.wallpaperWrapper}>
              <img className={styles.wallper} src={wallperMain} alt="фон" />
              <div className={styles.absoluteContainer}>
                {/* Operator */}
                <img
                  src={operator}
                  alt="оператор"
                  className={styles.operator}
                  ref={operatorRef}
                />
                {/* Loader returning */}
                <div
                  className={styles.loaderContainer}
                  ref={operatorCarLoaderRef}
                >
                  <img
                    src={carLoader}
                    alt="погрузчик"
                    className={`${styles.carLoader} ${styles.flipped}`}
                  />
                  <img
                    src={elementLoader}
                    alt="элемент"
                    className={`${styles.carLoader} ${styles.flippedElement}`}
                    ref={operatorPlatformRef}
                  />
                  {/* Render the item on the loader */}
                  {foundItem && (
                    <img
                      src={correctItem.src}
                      alt={correctItem.alt}
                      className={`${styles.itemOnLoader} ${
                        correctItem.extraClass || ""
                      }`}
                      ref={operatorItemRef}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          <div className={styles.buttonsContainer}>
            <button
              className={styles.buttonsRepeatAndSoundOnOff}
              onClick={handleSoundOnOff}
            >
              <img src={isSoundOn ? dynamicImg : dynamicOff} alt="динамик" />
              <span className={styles.tooltip}>Включить / выключить звук</span>
            </button>

            <button
              className={styles.buttonsRepeatAndSoundOnOff}
              onClick={handleRepeatWarehouseTask}
            >
              <img src={repeatTaskImg} alt="повтор звука" />
              <span className={styles.tooltip}>
                Нажмите для повторного прослушивания инструкции
              </span>
            </button>
          </div>
        </div>
        <div className={styles.fireworksContainer}></div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default LostThingsGame;
