import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./diamonds-with-surprise.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import wallper from "./images/wallper.png";
import man from "./images/man.png";
import closedEyes from "./images/closedEyes.png";
import curt from "./images/curt.png";
import maskCurt from "./images/maskCurt.png";
import dOne from "./images/dOne.png";
import dTwo from "./images/dTwo.png";
import dThree from "./images/dThree.png";
import dFour from "./images/dFour.png";
import dFive from "./images/dFive.png";
import dSix from "./images/dSix.png";
import dSeven from "./images/dSeven.png";
import dEight from "./images/dEight.png";
import dNine from "./images/dNine.png";
import dTen from "./images/dTen.png";
import trident from "./images/trident.png";
import tv from "./images/tv.png";
import dragonfly from "./images/dragonfly.png";
import rose from "./images/rose.png";
import flower from "./images/flower.png";
import jellyfish from "./images/jellyfish.png";
import goat from "./images/goat.png";
import vase from "./images/vase.png";
import tree from "./images/tree.png";
import mimosa from "./images/mimosa.png";

import startInstructionSound from "./sounds/startInstructionSound.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import clickSound from "./sounds/clickSound.mp3";
import diamondShotDiamondSound from "./sounds/diamondShotDiamondSound.mp3";
import curtSound from "./sounds/curtSound.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const DiamondsWithSurpriseGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [isCartVisible, setIsCartVisible] = useState(true);
  const [diamonds, setDiamonds] = useState([
    {
      id: 1,
      image: dOne,
      bigImage: trident,
      clicked: false,
      visible: true,
      top: "20%",
      left: "30%",
    },
    {
      id: 2,
      image: dTwo,
      bigImage: tv,
      clicked: false,
      visible: true,
      top: "80%",
      left: "3%",
    },
    {
      id: 3,
      image: dThree,
      bigImage: dragonfly,
      clicked: false,
      visible: true,
      top: "73%",
      left: "17%",
    },
    {
      id: 4,
      image: dFour,
      bigImage: rose,
      clicked: false,
      visible: true,
      top: "30%",
      left: "10%",
    },
    {
      id: 5,
      image: dFive,
      bigImage: flower,
      clicked: false,
      visible: true,
      top: "82%",
      left: "59%",
    },
    {
      id: 6,
      image: dSix,
      bigImage: jellyfish,
      clicked: false,
      visible: true,
      top: "82%",
      left: "86%",
    },
    {
      id: 7,
      image: dSeven,
      bigImage: goat,
      clicked: false,
      visible: true,
      top: "13%",
      left: "86%",
    },
    {
      id: 8,
      image: dEight,
      bigImage: vase,
      clicked: false,
      visible: true,
      top: "34%",
      left: "52%",
    },
    {
      id: 9,
      image: dNine,
      bigImage: tree,
      clicked: false,
      visible: true,
      top: "8%",
      left: "46%",
    },
    {
      id: 10,
      image: dTen,
      bigImage: mimosa,
      clicked: false,
      visible: true,
      top: "47%",
      left: "31%",
    },
  ]);
  const [diamondsInCart, setDiamondsInCart] = useState([]);

  const manRef = useRef(null);
  const closedEyesRef = useRef(null);
  const bigImageRef = useRef(null);
  const diamondRefs = useRef([]);
  const cartRef = useRef(null);
  const absoluteContainerRef = useRef(null);
  gsap.registerPlugin(MotionPathPlugin);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const clickAudioRef = useRef(new Audio(clickSound));
  const diamondShotDiamonAudiodRef = useRef(new Audio(diamondShotDiamondSound));
  const curtAudioRef = useRef(new Audio(curtSound));

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    const blinkAnimation = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    blinkAnimation
      .to(closedEyesRef.current, {
        opacity: 1,
        duration: 0.1,
      })
      .to(closedEyesRef.current, {
        opacity: 0,
        duration: 0.1,
        delay: 0.1,
      });
  }, []);

  const handleDiamondClick = (id, index) => {
    if (isInteractionBlocked) return;

    if (clickAudioRef.current) {
      clickAudioRef.current.play();
    }

    setIsInteractionBlocked(true);

    const clickedDiamond = diamonds.find((d) => d.id === id);

    // Показываем большое изображение
    if (bigImageRef.current) {
      bigImageRef.current.src = clickedDiamond.bigImage;
      gsap.set(bigImageRef.current, {
        display: "block",
        opacity: 0,
        scale: 0.5,
      });

      gsap.to(bigImageRef.current, {
        duration: 1,
        opacity: 1,
        scale: 1,
        ease: "power2.out",
        onComplete: () => {
          // После задержки скрываем большое изображение
          gsap.to(bigImageRef.current, {
            duration: 1,
            opacity: 0,
            delay: 2,
            onComplete: () => {
              gsap.set(bigImageRef.current, { display: "none" });
              // Начинаем анимацию перемещения алмаза в телегу
              animateDiamondToCart(id, index);
            },
          });
        },
      });
    }
  };

  const animateDiamondToCart = (id, index) => {
    const diamondElement = diamondRefs.current[index];
    const cartElement = cartRef.current;
    const containerElement = absoluteContainerRef.current;

    // Получаем размеры элементов
    const diamondRect = diamondElement.getBoundingClientRect();
    const cartRect = cartElement.getBoundingClientRect();
    const containerRect = containerElement.getBoundingClientRect();

    // Рассчитываем позиции относительно контейнера
    const diamondX =
      diamondRect.left - containerRect.left + diamondRect.width / 2;
    const diamondY =
      diamondRect.top - containerRect.top + diamondRect.height / 2;

    // Целевая позиция внутри телеги
    const cartX = cartRect.left - containerRect.left + cartRect.width * 0.5;
    const cartY = cartRect.top - containerRect.top + cartRect.height * 0.5;

    // Рассчитываем смещение
    const dx = cartX - diamondX;
    const dy = cartY - diamondY;

    // Устанавливаем абсолютное позиционирование для алмаза
    gsap.set(diamondElement, {
      position: "absolute",
      left: diamondRect.left - containerRect.left,
      top: diamondRect.top - containerRect.top,
      zIndex: 1000,
    });

    // Анимируем перемещение алмаза
    gsap.to(diamondElement, {
      duration: 1,
      x: dx,
      y: dy,
      ease: "power2.inOut",
      onComplete: () => {
        if (diamondShotDiamonAudiodRef.current) {
          diamondShotDiamonAudiodRef.current.play();
        }
        setDiamonds((prevDiamonds) => {
          const updatedDiamonds = prevDiamonds.map((diamond) =>
            diamond.id === id
              ? { ...diamond, clicked: true, visible: false, inCart: true }
              : diamond
          );

          // Проверяем, собраны ли все алмазы
          if (updatedDiamonds.every((diamond) => diamond.clicked)) {
            // Все алмазы собраны, запускаем анимацию телеги
            animateCartExit();
          }

          return updatedDiamonds;
        });

        setDiamondsInCart((prev) => [...prev, id]);

        gsap.set(diamondElement, { display: "none" });

        setIsInteractionBlocked(false);
      },
    });
  };

  const getCartPosition = (index) => {
    const baseBottom = 44; // Начальная позиция по вертикали (bottom: 44%)
    let baseLeft = 15; // Начальная позиция по горизонтали (left: 15%)
    const horizontalStep = -3; // Сдвиг по горизонтали влево для каждого алмаза в ряду
    const verticalStep = 5; // Шаг по вертикали для каждого нового ряда
    const secondRowShift = 40; // Сдвиг второго ряда вправо на 40%

    const row = Math.floor(index / 3); // Номер текущего ряда (0 или 1)
    const col = index % 3; // Позиция алмаза в ряду (0, 1, 2)

    // Сдвигаем второй ряд вправо на 40%
    if (row === 1) {
      baseLeft += secondRowShift;
    }

    const left = baseLeft + col * horizontalStep;
    const bottom = baseBottom + row * verticalStep;

    return { bottom: `${bottom}%`, left: `${left}%` };
  };

  const animateCartExit = () => {
    const cartElement = cartRef.current;
    const containerElement = absoluteContainerRef.current;

    const cartRect = cartElement.getBoundingClientRect();
    const containerRect = containerElement.getBoundingClientRect();

    const cartX = cartRect.left - containerRect.left;
    const cartY = cartRect.top - containerRect.top;

    if (curtAudioRef.current) {
      curtAudioRef.current.play();
    }

    // Устанавливаем абсолютное позиционирование для телеги
    gsap.set(cartElement, {
      position: "absolute",
      left: cartX,
      top: cartY,
    });

    // Анимируем перемещение телеги
    gsap.to(cartElement, {
      duration: 2,
      x: -500, // Смещение по X влево (отрицательное значение)
      y: 0, // Смещение по Y (настройте по необходимости)
      opacity: 0,
      ease: "power2.inOut",
      onComplete: () => {
        setIsCartVisible(false);
        triggerFireworks();
        setTimeout(() => {
          setIsGameCompleted(true);
        }, 3000);
      },
    });
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (clickAudioRef.current) {
        clickAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (diamondShotDiamonAudiodRef.current) {
        diamondShotDiamonAudiodRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (curtAudioRef.current) {
        curtAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      return newIsSoundOn;
    });
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      clickAudioRef.current.pause();
      clickAudioRef.current.currentTime = 0;

      diamondShotDiamonAudiodRef.current.pause();
      diamondShotDiamonAudiodRef.current.currentTime = 0;

      curtAudioRef.current.pause();
      curtAudioRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}

      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.wallpaperWrapper}>
            <img className={styles.wallper} src={wallper} alt="обои" />
            <div
              className={styles.absoluteContainer}
              ref={absoluteContainerRef}
            >
              <div className={styles.boyContainer} ref={manRef}>
                <img src={man} alt="искатель" className={styles.boy} />

                <img
                  src={closedEyes}
                  alt="моргание"
                  className={styles.closedEyes}
                  ref={closedEyesRef}
                />
              </div>

              <div className={styles.diamondsContainer}>
                {diamonds.map((diamond, index) =>
                  diamond.visible ? (
                    <img
                      key={diamond.id}
                      src={diamond.image}
                      alt={`Алмаз ${diamond.id}`}
                      className={styles.diamond}
                      style={{
                        position: "absolute",
                        top: diamond.top,
                        left: diamond.left,
                      }}
                      onClick={() => handleDiamondClick(diamond.id, index)}
                      ref={(el) => (diamondRefs.current[index] = el)}
                    />
                  ) : null
                )}
              </div>

              {isCartVisible && (
                <div className={styles.curtContainer} ref={cartRef}>
                  <img src={curt} alt="телега" className={styles.curt} />
                  <img
                    src={maskCurt}
                    alt="телега"
                    className={styles.maskCurt}
                  />

                  {diamondsInCart.map((diamondId, index) => {
                    const diamond = diamonds.find((d) => d.id === diamondId);
                    const position = getCartPosition(index);

                    return (
                      <img
                        key={diamond.id}
                        src={diamond.image}
                        alt={`Алмаз ${diamond.id}`}
                        className={styles.diamondInCart}
                        style={{
                          position: "absolute",
                          bottom: position.bottom,
                          left: position.left,
                          width: "40%",
                          zIndex: 1,
                        }}
                      />
                    );
                  })}
                </div>
              )}

              <img
                ref={bigImageRef}
                alt="Большое изображение"
                className={styles.bigImage}
                style={{ display: "none" }}
              />

              <div
                className={styles.buttonsContainer}
                style={{
                  pointerEvents: "auto",
                }}
              >
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className={styles.fireworksContainer}></div>
        </div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default DiamondsWithSurpriseGame;
