import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import Confetti from "react-confetti";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./lossing-boy.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import repeatTaskImg from "./images/repeatTask.png";
import wallper from "./images/wallper.png";
import bag from "./images/bag.png";
import ball from "./images/ball.png";
import boy from "./images/boy.png";
import closedEyes from "./images/closedEyes.png";
import conversation from "./images/conversation.svg";
import mother from "./images/mother.png";
import notebook from "./images/notebook.png";
import parrot from "./images/parrot.png";
import penalty from "./images/penalty.png";
import scarf from "./images/scarf.png";
import shirt from "./images/shirt.png";
import sock from "./images/sock.png";
import watch from "./images/watch.png";
import branch from "./images/branch.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import notebookSound from "./sounds/notebookSound.mp3";
import scarfSound from "./sounds/scarfSound.mp3";
import bagSound from "./sounds/bagSound.mp3";
import sockSound from "./sounds/sockSound.mp3";
import ballSound from "./sounds/ballSound.mp3";
import shirtSound from "./sounds/shirtSound.mp3";
import penaltySound from "./sounds/penaltySound.mp3";
import watchSound from "./sounds/watchSound.mp3";
import parrotSound from "./sounds/parrotSound.mp3";
import parrotSecondSound from "./sounds/parrotSecondSound.mp3";
import conversationSound from "./sounds/conversationSound.mp3";
import correctAnswerSound from "./sounds/yes.mp3";
import praiseSound from "./sounds/praise.mp3";

const LossingBoyGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [hasCurrentTaskAudioPlayed, setHasCurrentTaskAudioPlayed] =
    useState(false);

  const boyRef = useRef(null);
  const closedEyesRef = useRef(null);

  const parrotRef = useRef(null);
  const conversationRef = useRef(null);
  const branchRef = useRef(null);

  const absoluteContainerRef = useRef(null);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const notebookAudioRef = useRef(new Audio(notebookSound));
  const scarfAudioRef = useRef(new Audio(scarfSound));
  const bagAudioRef = useRef(new Audio(bagSound));
  const sockAudioRef = useRef(new Audio(sockSound));
  const ballAudioRef = useRef(new Audio(ballSound));
  const shirtAudioRef = useRef(new Audio(shirtSound));
  const penaltyAudioRef = useRef(new Audio(penaltySound));
  const watchAudioRef = useRef(new Audio(watchSound));
  const parrotAudioRef = useRef(new Audio(parrotSound));
  const parrotSecondAudioRef = useRef(new Audio(parrotSecondSound));
  const conversationAudioRef = useRef(new Audio(conversationSound));
  const correctAnswerAudioRef = useRef(new Audio(correctAnswerSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const tasks = [
    {
      name: "notebook",
      className: styles.notebook,
      audioRef: notebookAudioRef,
    },
    {
      name: "scarf",
      className: styles.scarf,
      audioRef: scarfAudioRef,
    },
    {
      name: "bag",
      className: styles.bag,
      audioRef: bagAudioRef,
    },
    {
      name: "sock",
      className: styles.sock,
      audioRef: sockAudioRef,
    },
    {
      name: "ball",
      className: styles.ball,
      audioRef: ballAudioRef,
    },
    {
      name: "shirt",
      className: styles.shirt,
      audioRef: shirtAudioRef,
    },
    {
      name: "penalty",
      className: styles.penalty,
      audioRef: penaltyAudioRef,
    },
    {
      name: "watch",
      className: styles.watch,
      audioRef: watchAudioRef,
    },
    {
      name: "parrot",
      className: styles.parrot,
      audioRef: parrotAudioRef,
    },
  ];

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        playCurrentTaskAudio(currentTaskIndex);
      };
    }

    setIsButtonVisible(false);
  };

  const playCurrentTaskAudio = (index) => {
    setIsInteractionBlocked(true);
    const currentTask = tasks[index];
    if (currentTask && currentTask.audioRef.current) {
      currentTask.audioRef.current.play();
      currentTask.audioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        setHasCurrentTaskAudioPlayed(true); // Аудио было проиграно
      };
    } else {
      setIsInteractionBlocked(false);
    }
  };

  const clickOnSubject = (e) => {
    if (isInteractionBlocked) return;

    const clickedElement = e.target;

    if (clickedElement.classList.contains(styles.parrot)) {
      handleParrotClick();
    } else if (clickedElement.classList.contains(styles.branch)) {
      handleBranchClick();
    } else {
      handleItemClick(e);
    }
  };

  const handleParrotClick = () => {
    if (isInteractionBlocked) return;

    const currentTask = tasks[currentTaskIndex];

    if (currentTask.className !== styles.parrot) {
      gsap.fromTo(
        parrotRef.current,
        { x: -5 },
        {
          x: 5,
          duration: 0.1,
          yoyo: true,
          repeat: 5,
          onComplete: () => {
            gsap.set(parrotRef.current, { x: 0 });
          },
        }
      );
      return;
    }

    setIsInteractionBlocked(true);

    gsap.set([conversationRef.current, branchRef.current], {
      display: "block",
      opacity: 0,
    });

    const tl = gsap.timeline();

    tl.to(conversationRef.current, {
      opacity: 1,
      duration: 0.5,
      ease: "power1.inOut",
    });

    tl.to(
      branchRef.current,
      {
        opacity: 1,
        duration: 0.5,
        ease: "power1.inOut",
      },
      "-=0.3"
    );

    if (conversationAudioRef.current) {
      conversationAudioRef.current.play();

      conversationAudioRef.current.onended = () => {
        if (parrotSecondAudioRef.current) {
          parrotSecondAudioRef.current.play();

          parrotSecondAudioRef.current.onended = () => {
            setIsInteractionBlocked(false);
          };
        } else {
          setIsInteractionBlocked(false);
        }
      };
    } else {
      setIsInteractionBlocked(false);
    }
  };

  const handleBranchClick = () => {
    if (isInteractionBlocked) return;

    const currentTask = tasks[currentTaskIndex];

    if (correctAnswerAudioRef.current) {
      correctAnswerAudioRef.current.play();
    }

    if (currentTask.className !== styles.parrot) {
      gsap.fromTo(
        branchRef.current,
        { x: -5 },
        {
          x: 5,
          duration: 0.1,
          yoyo: true,
          repeat: 5,
          onComplete: () => {
            gsap.set(branchRef.current, { x: 0 });
          },
        }
      );
      return;
    }

    setIsInteractionBlocked(true);

    // Анимация исчезновения ветки и диалогового окна
    const tl = gsap.timeline({
      onComplete: () => {
        // Удаляем ветку и диалоговое окно из DOM
        conversationRef.current.remove();
        branchRef.current.remove();

        // Переходим к следующей задаче
        if (currentTaskIndex + 1 < tasks.length) {
          const newIndex = currentTaskIndex + 1;
          setCurrentTaskIndex(newIndex);
          playCurrentTaskAudio(newIndex);
        } else {
          setShowConfetti(true);
          if (praiseSoundRef.current) {
            praiseSoundRef.current.play();
          }
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 3000);
        }

        setIsInteractionBlocked(false);
      },
    });

    tl.to([branchRef.current, conversationRef.current], {
      opacity: 0,
      duration: 0.5,
      ease: "power1.inOut",
    });
  };

  const handleItemClick = (e) => {
    if (isInteractionBlocked) return;

    const clickedElement = e.target;
    const currentTask = tasks[currentTaskIndex];

    if (clickedElement.classList.contains(currentTask.className)) {
      setIsInteractionBlocked(true);

      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.play();
      }

      animateItem(clickedElement, () => {
        if (currentTaskIndex + 1 < tasks.length) {
          const newIndex = currentTaskIndex + 1;
          setCurrentTaskIndex(newIndex);
          playCurrentTaskAudio(newIndex);
        } else {
          setIsGameCompleted(true);

          if (praiseSoundRef.current) {
            praiseSoundRef.current.play();
          }
        }

        setIsInteractionBlocked(false);
      });
    } else {
      // Неправильный выбор
      // Предмет трясётся
      gsap.fromTo(
        clickedElement,
        { x: -5 },
        {
          x: 5,
          duration: 0.1,
          yoyo: true,
          repeat: 5,
          onComplete: () => {
            gsap.set(clickedElement, { x: 0 });
          },
        }
      );
    }
  };

  const animateItem = (element, onComplete) => {
    // Получаем центр контейнера
    const containerRect = absoluteContainerRef.current.getBoundingClientRect();
    const containerCenterX = containerRect.left + containerRect.width / 2;
    const containerCenterY = containerRect.top + containerRect.height / 2;

    // Получаем текущие координаты элемента
    const elementRect = element.getBoundingClientRect();
    const elementCenterX = elementRect.left + elementRect.width / 2;
    const elementCenterY = elementRect.top + elementRect.height / 2;

    // Вычисляем расстояние до центра
    const deltaX = containerCenterX - elementCenterX;
    const deltaY = containerCenterY - elementCenterY;

    const tl = gsap.timeline({
      onComplete: () => {
        // Удаляем элемент из DOM
        element.remove();

        // Вызываем колбэк после завершения анимации
        if (onComplete) onComplete();
      },
    });

    // Анимация перемещения и исчезновения
    tl.to(element, {
      x: deltaX,
      y: deltaY,
      scale: 1.5,
      duration: 2,
      ease: "power1.inOut",
    }).to(
      element,
      {
        opacity: 0,
        duration: 1,
        ease: "power1.out",
      },
      "-=1"
    );
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (notebookAudioRef.current) {
        notebookAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (scarfAudioRef.current) {
        scarfAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (bagAudioRef.current) {
        bagAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (sockAudioRef.current) {
        sockAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (ballAudioRef.current) {
        ballAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (shirtAudioRef.current) {
        shirtAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (penaltyAudioRef.current) {
        penaltyAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (watchAudioRef.current) {
        watchAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (parrotAudioRef.current) {
        parrotAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (parrotSecondAudioRef.current) {
        parrotSecondAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (conversationAudioRef.current) {
        conversationAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (praiseSoundRef.current) {
        praiseSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      return newIsSoundOn;
    });
  };

  useEffect(() => {
    const blinkAnimation = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    blinkAnimation
      .to(closedEyesRef.current, {
        opacity: 1,
        duration: 0.1,
      })
      .to(closedEyesRef.current, {
        opacity: 0,
        duration: 0.1,
        delay: 0.1,
      });
  }, []);

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;

      notebookAudioRef.current.pause();
      notebookAudioRef.current.currentTime = 0;

      scarfAudioRef.current.pause();
      scarfAudioRef.current.currentTime = 0;

      bagAudioRef.current.pause();
      bagAudioRef.current.currentTime = 0;

      sockAudioRef.current.pause();
      sockAudioRef.current.currentTime = 0;

      ballAudioRef.current.pause();
      ballAudioRef.current.currentTime = 0;

      shirtAudioRef.current.pause();
      shirtAudioRef.current.currentTime = 0;

      penaltyAudioRef.current.pause();
      penaltyAudioRef.current.currentTime = 0;

      watchAudioRef.current.pause();
      watchAudioRef.current.currentTime = 0;

      parrotAudioRef.current.pause();
      parrotAudioRef.current.currentTime = 0;

      parrotSecondAudioRef.current.pause();
      parrotSecondAudioRef.current.currentTime = 0;

      conversationAudioRef.current.pause();
      conversationAudioRef.current.currentTime = 0;

      correctAnswerAudioRef.current.pause();
      correctAnswerAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);

  useEffect(() => {
    setHasCurrentTaskAudioPlayed(false);
  }, [currentTaskIndex]);

  const handleRepeatTask = () => {
    // Если взаимодействие заблокировано, не выполняем повтор
    if (isInteractionBlocked) return;

    // Нельзя повторять звук, если он ещё не был проигран
    if (!hasCurrentTaskAudioPlayed) return;

    // Если аудио уже воспроизводится, игнорируем нажатие
    if (isAudioPlaying) return;

    setIsInteractionBlocked(true);
    setIsAudioPlaying(true);

    const currentTask = tasks[currentTaskIndex];

    if (currentTask.className === styles.parrot) {
      if (
        conversationRef.current &&
        conversationRef.current.style.opacity === "1"
      ) {
        // Воспроизводим второе аудио для попугая
        if (parrotSecondAudioRef.current) {
          parrotSecondAudioRef.current.currentTime = 0;
          parrotSecondAudioRef.current.play();
          parrotSecondAudioRef.current.onended = () => {
            setIsInteractionBlocked(false);
            setIsAudioPlaying(false);
          };
        } else {
          setIsInteractionBlocked(false);
          setIsAudioPlaying(false);
        }
      } else {
        // Воспроизводим первое аудио для попугая
        if (parrotAudioRef.current) {
          parrotAudioRef.current.currentTime = 0;
          parrotAudioRef.current.play();
          parrotAudioRef.current.onended = () => {
            setIsInteractionBlocked(false);
            setIsAudioPlaying(false);
          };
        } else {
          setIsInteractionBlocked(false);
          setIsAudioPlaying(false);
        }
      }
    } else {
      // Повторяем звук для текущей задачи
      if (currentTask.audioRef && currentTask.audioRef.current) {
        currentTask.audioRef.current.currentTime = 0;
        currentTask.audioRef.current.play();
        currentTask.audioRef.current.onended = () => {
          setIsInteractionBlocked(false);
          setIsAudioPlaying(false);
        };
      } else {
        setIsInteractionBlocked(false);
        setIsAudioPlaying(false);
      }
    }
  };

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && <Confetti />}

      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer} onClick={clickOnSubject}>
          <div className={styles.wallpaperWrapper}>
            <img className={styles.wallper} src={wallper} alt="обои" />
            <div
              className={styles.absoluteContainer}
              ref={absoluteContainerRef}
            >
              <div className={styles.boyContainer} ref={boyRef}>
                <img src={boy} alt="мальчик" className={styles.boy} />

                <img
                  src={closedEyes}
                  alt="моргание"
                  className={styles.closedEyes}
                  ref={closedEyesRef}
                />
              </div>
              <img className={styles.mother} src={mother} alt="мама" />
              <img className={styles.watch} src={watch} alt="часы" />
              <img className={styles.notebook} src={notebook} alt="тетрадь" />
              <img className={styles.bag} src={bag} alt="сумка" />
              <img className={styles.shirt} src={shirt} alt="рубашка" />
              <img className={styles.penalty} src={penalty} alt="пенал" />
              <img className={styles.scarf} src={scarf} alt="шарф" />
              <img className={styles.sock} src={sock} alt="носок" />
              <img className={styles.ball} src={ball} alt="мяч" />

              <div className={styles.parrotContainer} ref={parrotRef}>
                <img src={parrot} alt="попугай" className={styles.parrot} />

                <img
                  src={conversation}
                  alt="диалоговое окно"
                  className={styles.conversation}
                  ref={conversationRef}
                />
                <img
                  src={branch}
                  className={styles.branch}
                  alt="ветка"
                  ref={branchRef}
                />
              </div>

              <div className={styles.buttonsContainer}>
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>

                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleRepeatTask}
                >
                  <img src={repeatTaskImg} alt="повтор звука" />
                  <span className={styles.tooltip}>
                    Нажмите для повторного прослушивания инструкции
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default LossingBoyGame;
