import React, { useState, useRef, useEffect } from "react";
import ShoppingAheadFirstPart from "./shopping-ahead";
import ShoppingAheadSecondPart from "./shopping-second";
import ShoppingAheadThirdPart from "./shopping-third";
import ShoppingAheadFourPart from "./shopping-four";

import backgroundSound from "./sounds/backgroundSound.mp3";
import breadSectionSounds from "./sounds/breadSectionSounds.mp3";
import milkSectionSounds from "./sounds/milkSectionSounds.mp3";
import vegetablesSectionSounds from "./sounds/vegetablesSectionSounds.mp3";
import fruitsSectionSounds from "./sounds/fruitsSectionSounds.mp3";
import dessertSectionSounds from "./sounds/dessertSectionSounds.mp3";
import praiseSecondPart from "./sounds/praiseSecondPart.mp3";
import correctAnswerSound from "./sounds/yes.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import thirdInstructionSound from "./sounds/thirdInstructionSound.mp3";
import cashierSound from "./sounds/cashierSound.mp3";
import bagSound from "./sounds/bagSound.mp3";

import fourInstructionSound from "./sounds/fourInstructionSound.mp3";
import coinSound from "./sounds/coinSound.mp3";
import breadSumSound from "./sounds/breadSumSound.mp3";
import milkSumSound from "./sounds/milkSumSound.mp3";
import vegetablesSumSound from "./sounds/vegetablesSumSound.mp3";
import fruitsSumSound from "./sounds/fruitsSumSound.mp3";
import sweetsSumSound from "./sounds/sweetsSumSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const ShoppingAheadGame = () => {
  const [scene, setScene] = useState("ShoppingAheadFirstPart");

  const backgroundAudioRef = useRef(null);
  const breadSectionAudioRef = useRef(null);
  const milkSectionAudioRef = useRef(null);
  const fruitsSectionAudioRef = useRef(null);
  const vegetablesSectionAudioRef = useRef(null);
  const dessertSectionAudioRef = useRef(null);
  const praiseSecondPartAudioRef = useRef(null);
  const correctAnswerAudioRef = useRef(null);
  const incorrectAnswerAudioRef = useRef(null);

  const thirdInstructionAudioRef = useRef(null);
  const cashierAudioRef = useRef(null);
  const bagAudioRef = useRef(null);

  const fourInstructionAudioRef = useRef(null);
  const coinAudioRef = useRef(null);
  const breadSumAudioRef = useRef(null);
  const milkSumAudioRef = useRef(null);
  const vegetablesSumAudioRef = useRef(null);
  const fruitsSumAudioRef = useRef(null);
  const sweetsSumAudioRef = useRef(null);
  const praiseAudioRef = useRef(null);

  useEffect(() => {
    backgroundAudioRef.current = new Audio(backgroundSound);
    breadSectionAudioRef.current = new Audio(breadSectionSounds);
    milkSectionAudioRef.current = new Audio(milkSectionSounds);
    fruitsSectionAudioRef.current = new Audio(fruitsSectionSounds);
    vegetablesSectionAudioRef.current = new Audio(vegetablesSectionSounds);
    dessertSectionAudioRef.current = new Audio(dessertSectionSounds);
    praiseSecondPartAudioRef.current = new Audio(praiseSecondPart);
    correctAnswerAudioRef.current = new Audio(correctAnswerSound);
    incorrectAnswerAudioRef.current = new Audio(incorrectAnswerSound);

    thirdInstructionAudioRef.current = new Audio(thirdInstructionSound);
    cashierAudioRef.current = new Audio(cashierSound);
    bagAudioRef.current = new Audio(bagSound);

    fourInstructionAudioRef.current = new Audio(fourInstructionSound);
    coinAudioRef.current = new Audio(coinSound);
    breadSumAudioRef.current = new Audio(breadSumSound);
    milkSumAudioRef.current = new Audio(milkSumSound);
    vegetablesSumAudioRef.current = new Audio(vegetablesSumSound);
    fruitsSumAudioRef.current = new Audio(fruitsSumSound);
    sweetsSumAudioRef.current = new Audio(sweetsSumSound);
    praiseAudioRef.current = new Audio(praiseSound);

    return () => {
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      if (breadSectionAudioRef.current) {
        breadSectionAudioRef.current.pause();
        breadSectionAudioRef.current.currentTime = 0;
      }

      if (milkSectionAudioRef.current) {
        milkSectionAudioRef.current.pause();
        milkSectionAudioRef.current.currentTime = 0;
      }
      if (fruitsSectionAudioRef.current) {
        fruitsSectionAudioRef.current.pause();
        fruitsSectionAudioRef.current.currentTime = 0;
      }
      if (vegetablesSectionAudioRef.current) {
        vegetablesSectionAudioRef.current.pause();
        vegetablesSectionAudioRef.current.currentTime = 0;
      }

      if (dessertSectionAudioRef.current) {
        dessertSectionAudioRef.current.pause();
        dessertSectionAudioRef.current.currentTime = 0;
      }

      if (praiseSecondPartAudioRef.current) {
        praiseSecondPartAudioRef.current.pause();
        praiseSecondPartAudioRef.current.currentTime = 0;
      }

      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.pause();
        correctAnswerAudioRef.current.currentTime = 0;
      }

      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.pause();
        incorrectAnswerAudioRef.current.currentTime = 0;
      }

      if (thirdInstructionAudioRef.current) {
        thirdInstructionAudioRef.current.pause();
        thirdInstructionAudioRef.current.currentTime = 0;
      }

      if (cashierAudioRef.current) {
        cashierAudioRef.current.pause();
        cashierAudioRef.current.currentTime = 0;
      }

      if (bagAudioRef.current) {
        bagAudioRef.current.pause();
        bagAudioRef.current.currentTime = 0;
      }

      if (fourInstructionAudioRef.current) {
        fourInstructionAudioRef.current.pause();
        fourInstructionAudioRef.current.currentTime = 0;
      }

      if (coinAudioRef.current) {
        coinAudioRef.current.pause();
        coinAudioRef.current.currentTime = 0;
      }

      if (breadSumAudioRef.current) {
        breadSumAudioRef.current.pause();
        breadSumAudioRef.current.currentTime = 0;
      }

      if (milkSumAudioRef.current) {
        milkSumAudioRef.current.pause();
        milkSumAudioRef.current.currentTime = 0;
      }

      if (vegetablesSumAudioRef.current) {
        vegetablesSumAudioRef.current.pause();
        vegetablesSumAudioRef.current.currentTime = 0;
      }

      if (fruitsSumAudioRef.current) {
        fruitsSumAudioRef.current.pause();
        fruitsSumAudioRef.current.currentTime = 0;
      }

      if (sweetsSumAudioRef.current) {
        sweetsSumAudioRef.current.pause();
        sweetsSumAudioRef.current.currentTime = 0;
      }

      if (praiseAudioRef.current) {
        praiseAudioRef.current.pause();
        praiseAudioRef.current.currentTime = 0;
      }
    };
  }, []);

  const playBackgroundMusic = () => {
    if (backgroundAudioRef.current) {
      backgroundAudioRef.current.loop = true;
      backgroundAudioRef.current.play();
    }
  };

  const setBackgroundMusicVolume = (volume) => {
    if (backgroundAudioRef.current) {
      backgroundAudioRef.current.volume = volume;
    }
  };

  const handleNextScene = () => {
    if (scene === "ShoppingAheadFirstPart") {
      setScene("ShoppingAheadSecondPart");
    } else if (scene === "ShoppingAheadSecondPart") {
      setScene("ShoppingAheadThirdPart");
    } else if (scene === "ShoppingAheadThirdPart") {
      setScene("ShoppingAheadFourPart");
    }
  };

  return (
    <div>
      {scene === "ShoppingAheadFirstPart" && (
        <ShoppingAheadFirstPart
          onNextScene={handleNextScene}
          playBackgroundMusic={playBackgroundMusic}
          setBackgroundMusicVolume={setBackgroundMusicVolume}
        />
      )}
      {scene === "ShoppingAheadSecondPart" && (
        <ShoppingAheadSecondPart
          onNextScene={handleNextScene}
          audioRefs={{
            breadSectionAudioRef,
            milkSectionAudioRef,
            fruitsSectionAudioRef,
            vegetablesSectionAudioRef,
            dessertSectionAudioRef,
            praiseSecondPartAudioRef,
            correctAnswerAudioRef,
            incorrectAnswerAudioRef,
          }}
          playBackgroundMusic={playBackgroundMusic}
          setBackgroundMusicVolume={setBackgroundMusicVolume}
        />
      )}
      {scene === "ShoppingAheadThirdPart" && (
        <ShoppingAheadThirdPart
          onNextScene={handleNextScene}
          audioRefs={{ thirdInstructionAudioRef, cashierAudioRef, bagAudioRef }}
          playBackgroundMusic={playBackgroundMusic}
          setBackgroundMusicVolume={setBackgroundMusicVolume}
        />
      )}
      {scene === "ShoppingAheadFourPart" && (
        <ShoppingAheadFourPart
          onNextScene={handleNextScene}
          audioRefs={{
            fourInstructionAudioRef,
            coinAudioRef,
            breadSumAudioRef,
            milkSumAudioRef,
            vegetablesSumAudioRef,
            fruitsSumAudioRef,
            sweetsSumAudioRef,
            praiseAudioRef,
            incorrectAnswerAudioRef,
          }}
          playBackgroundMusic={playBackgroundMusic}
          setBackgroundMusicVolume={setBackgroundMusicVolume}
        />
      )}
    </div>
  );
};

export default ShoppingAheadGame;
