import React, { useState, useRef, useEffect } from "react";
import Confetti from "react-confetti";
import { gsap } from "gsap";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./lockers.module.css";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import repeatTaskImg from "./images/repeatTask.png";
import boy from "./images/boy.png";
import closedEyes from "./images/closedEyes.png";
import doorBlue from "./images/doorBlue.png";
import doorCircle from "./images/doorCircle.png";
import doorGreen from "./images/doorGreen.png";
import doorPurple from "./images/doorPurple.png";
import doorRed from "./images/doorRed.png";
import doorTriangleMulticolor from "./images/doorTriangleMulticolor.png";
import doorYellow from "./images/doorYellow.png";
import doorYellowOval from "./images/doorYellowOval.png";
import closedTriangleMulticolor from "./images/closedTriangleMulticolor.png";
import openTriangleMulticolor from "./images/openTriangleMulticolor.png";
import openPurple from "./images/openPurple.png";
import closedPurple from "./images/closedPurple.png";
import closedYellow from "./images/closedYellow.png";
import openYellow from "./images/openYellow.png";
import circleOpen from "./images/circleOpen.png";
import circleClosed from "./images/circleClosed.png";
import closedRed from "./images/closedRed.png";
import openRed from "./images/openRed.png";
import greenOpen from "./images/greenOpen.png";
import greenClosed from "./images/greenClosed.png";
import openYellowStar from "./images/openYellowStar.png";
import closedYellowStar from "./images/closedYellowStar.png";
import blueOpen from "./images/blueOpen.png";
import blueClosed from "./images/blueClosed.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import lockerClosedSound from "./sounds/lockerClosedSound.mp3";
import clickSound from "./sounds/clickSound.mp3";
import incorrectAnswer from "./sounds/no.mp3";
import praiseSound from "./sounds/praise.mp3";

import zeroTaskSoundA from "./sounds/zeroTaskSoundA.mp3";
import zeroTaskSoundB from "./sounds/zeroTaskSoundB.mp3";
import oneTaskSoundA from "./sounds/oneTaskSoundA.mp3";
import oneTaskSoundB from "./sounds/oneTaskSoundB.mp3";
import twoTaskSoundA from "./sounds/twoTaskSoundA.mp3";
import twoTaskSoundB from "./sounds/twoTaskSoundB.mp3";
import threeTaskSoundA from "./sounds/threeTaskSoundA.mp3";
import threeTaskSoundB from "./sounds/threeTaskSoundB.mp3";
import fourTaskSoundA from "./sounds/fourTaskSoundA.mp3";
import fourTaskSoundB from "./sounds/fourTaskSoundB.mp3";
import fiveTaskSoundA from "./sounds/fiveTaskSoundA.mp3";
import fiveTaskSoundB from "./sounds/fiveTaskSoundB.mp3";
import sixTaskSoundA from "./sounds/sixTaskSoundA.mp3";
import sixTaskSoundB from "./sounds/sixTaskSoundB.mp3";
import sevenTaskSoundA from "./sounds/sevenTaskSoundA.mp3";
import sevenTaskSoundB from "./sounds/sevenTaskSoundB.mp3";

const tasks = [
  {
    id: 1,
    door: {
      id: "door1",
      src: doorRed,
      alt: "Красная дверь",
    },
    correctLock: {
      id: "lock1",
      closedSrc: openPurple,
      openSrc: closedPurple,
      alt: "замок",
    },
  },
  {
    id: 2,
    door: {
      id: "door2",
      src: doorYellowOval,
      alt: "желтая дверь",
    },
    correctLock: {
      id: "lock2",
      closedSrc: openYellow,
      openSrc: closedYellow,
      alt: "замок",
    },
  },
  {
    id: 3,
    door: {
      id: "door3",
      src: doorBlue,
      alt: "Синяя дверь",
    },
    correctLock: {
      id: "lock3",
      closedSrc: circleOpen,
      openSrc: circleClosed,
      alt: "замок",
    },
  },
  {
    id: 4,
    door: {
      id: "door4",
      src: doorCircle,
      alt: "круглая дверь",
    },
    correctLock: {
      id: "lock4",
      closedSrc: greenOpen,
      openSrc: greenClosed,
      alt: "замок",
    },
  },
  {
    id: 5,
    door: {
      id: "door5",
      src: doorYellow,
      alt: "дверь",
    },
    correctLock: {
      id: "lock5",
      closedSrc: openTriangleMulticolor,
      openSrc: closedTriangleMulticolor,
      alt: "замок",
    },
  },
  {
    id: 6,
    door: {
      id: "door6",
      src: doorGreen,
      alt: "дверь",
    },
    correctLock: {
      id: "lock6",
      closedSrc: blueOpen,
      openSrc: blueClosed,
      alt: "Синий замок",
    },
  },
  {
    id: 7,
    door: {
      id: "door7",
      src: doorTriangleMulticolor,
      alt: "дверь",
    },
    correctLock: {
      id: "lock7",
      closedSrc: openYellowStar,
      openSrc: closedYellowStar,
      alt: "замок",
    },
  },
  {
    id: 8,
    door: {
      id: "door8",
      src: doorPurple,
      alt: "дверь",
    },
    correctLock: {
      id: "lock8",
      closedSrc: openRed,
      openSrc: closedRed,
      alt: "замок",
    },
  },
];

const taskSounds = [
  {
    soundA: zeroTaskSoundA,
    soundB: zeroTaskSoundB,
  },
  {
    soundA: oneTaskSoundA,
    soundB: oneTaskSoundB,
  },
  {
    soundA: twoTaskSoundA,
    soundB: twoTaskSoundB,
  },

  {
    soundA: threeTaskSoundA,
    soundB: threeTaskSoundB,
  },

  {
    soundA: fourTaskSoundA,
    soundB: fourTaskSoundB,
  },

  {
    soundA: fiveTaskSoundA,
    soundB: fiveTaskSoundB,
  },

  {
    soundA: sixTaskSoundA,
    soundB: sixTaskSoundB,
  },

  {
    soundA: sevenTaskSoundA,
    soundB: sevenTaskSoundB,
  },
];

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const LockersGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [matchedLocks, setMatchedLocks] = useState([]);
  const [shuffledLocks] = useState(() =>
    shuffleArray(tasks.map((task) => task.correctLock))
  );

  useEffect(() => {});

  const boyRef = useRef(null);
  const closedEyesRef = useRef(null);
  const locksContainerRef = useRef(null);
  const doorsContainerRef = useRef(null);
  const lockRefs = useRef({});

  const audioRef = useRef(null);
  const taskAudioRefs = useRef([]);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const lockerClosedAudioRef = useRef(new Audio(lockerClosedSound));
  const clickAudioRef = useRef(new Audio(clickSound));
  const incorrectAnswerAudioRef = useRef(new Audio(incorrectAnswer));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  useEffect(() => {
    taskAudioRefs.current = taskSounds.map((taskSound) => ({
      audioA: new Audio(taskSound.soundA),
      audioB: new Audio(taskSound.soundB),
    }));
  }, []);

  const handleNextTask = () => {
    setIsInteractionBlocked(true); // Блокируем взаимодействие

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);

        playTaskSounds(currentTaskIndex);

        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true; // Включаем повтор для фоновой музыки
          backgroundAudioRef.current.play(); // Запускаем фоновую музыку
        }
      };
    }

    setIsButtonVisible(false);
  };

  const playTaskSounds = (index) => {
    setIsInteractionBlocked(true);
    const taskAudio = taskAudioRefs.current[index];
    if (taskAudio) {
      taskAudio.audioA.play();
      taskAudio.audioA.onended = () => {
        taskAudio.audioB.play();
        taskAudio.audioB.onended = () => {
          setIsInteractionBlocked(false);
        };
      };
    } else {
      setIsInteractionBlocked(false);
    }
  };

  useEffect(() => {
    const blinkAnimation = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    blinkAnimation
      .to(closedEyesRef.current, {
        opacity: 1,
        duration: 0.1,
      })
      .to(closedEyesRef.current, {
        opacity: 0,
        duration: 0.1,
        delay: 0.1,
      });
  }, []);

  const handleLockClick = (lock) => {
    if (isInteractionBlocked) return;

    if (clickAudioRef.current) {
      clickAudioRef.current.play();
    }

    const currentTask = tasks[currentTaskIndex];
    if (!currentTask) return;

    if (lock.id !== currentTask.correctLock.id) {
      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.play();
      }
      const lockElement = lockRefs.current[lock.id];
      if (lockElement) {
        gsap.fromTo(
          lockElement,
          { x: 0 },
          {
            x: 20,
            duration: 0.1,
            yoyo: true,
            repeat: 5,
            ease: "power1.inOut",
          }
        );
      }

      return;
    }

    // Правильный замок
    setIsInteractionBlocked(true);

    // Получаем элементы замка и двери
    const lockElement = lockRefs.current[lock.id];
    const doorElement = doorsContainerRef.current.querySelector(
      `[data-door-id="${currentTask.door.id}"]`
    );

    if (lockElement && doorElement) {
      const lockRect = lockElement.getBoundingClientRect();
      const doorRect = doorElement.getBoundingClientRect();

      // Вычисляем смещение
      const deltaX = doorRect.left - lockRect.left;
      const deltaY = doorRect.top - lockRect.top;

      // Анимируем замок к двери
      gsap.to(lockElement, {
        x: deltaX,
        y: deltaY,
        duration: 1,
        ease: "power1.inOut",
        onComplete: () => {
          // После анимации фиксируем замок на двери
          gsap.set(lockElement, {
            x: 0,
            y: 0,
            position: "relative",
            top: doorRect.top,
            left: doorRect.left,
          });

          // Обновляем matchedLocks после анимации
          setMatchedLocks((prev) => [
            ...prev,
            { doorId: currentTask.door.id, lockId: lock.id },
          ]);

          if (lockerClosedAudioRef.current) {
            lockerClosedAudioRef.current.play();
          }

          // Переходим к следующей задаче после задержки
          setTimeout(() => {
            setIsInteractionBlocked(false);
            if (currentTaskIndex < tasks.length - 1) {
              const newIndex = currentTaskIndex + 1;
              setCurrentTaskIndex(newIndex);
              playTaskSounds(newIndex);
            } else {
              if (praiseSoundRef.current) {
                praiseSoundRef.current.play();
              }
              setShowConfetti(true);

              setTimeout(() => {
                setIsGameCompleted(true);
              }, 3000);
            }
          }, 1000); // Задержка между задачами
        },
      });
    }
  };

  const availableLocks = shuffledLocks.filter(
    (lock) => !matchedLocks.find((m) => m.lockId === lock.id)
  );

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (clickAudioRef.current) {
        clickAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (lockerClosedAudioRef.current) {
        lockerClosedAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (taskAudioRefs.current && taskAudioRefs.current.length > 0) {
        taskAudioRefs.current.forEach((taskAudio) => {
          if (taskAudio.audioA) {
            taskAudio.audioA.volume = newIsSoundOn ? 1 : 0;
          }
          if (taskAudio.audioB) {
            taskAudio.audioB.volume = newIsSoundOn ? 1 : 0;
          }
        });
      }

      return newIsSoundOn;
    });
  };

  const handleRepeatTask = () => {
    const currentTaskAudio = taskAudioRefs.current[currentTaskIndex];

    if (currentTaskAudio && !isInteractionBlocked) {
      setIsInteractionBlocked(true);
      currentTaskAudio.audioA.currentTime = 0;
      currentTaskAudio.audioB.currentTime = 0;

      currentTaskAudio.audioA.play();

      currentTaskAudio.audioA.onended = () => {
        currentTaskAudio.audioB.play();
        currentTaskAudio.audioB.onended = () => {
          setIsInteractionBlocked(false);
        };
      };
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;

      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      lockerClosedAudioRef.current.pause();
      lockerClosedAudioRef.current.currentTime = 0;

      clickAudioRef.current.pause();
      clickAudioRef.current.currentTime = 0;

      incorrectAnswerAudioRef.current.pause();
      incorrectAnswerAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      if (taskAudioRefs.current && taskAudioRefs.current.length > 0) {
        taskAudioRefs.current.forEach((taskAudio) => {
          if (taskAudio.audioA) {
            taskAudio.audioA.pause();
            taskAudio.audioA.currentTime = 0;
            taskAudio.audioA.onended = null;
          }
          if (taskAudio.audioB) {
            taskAudio.audioB.pause();
            taskAudio.audioB.currentTime = 0;
            taskAudio.audioB.onended = null;
          }
        });
      }

      document.body.style.backgroundColor = null;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && <Confetti />}

      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.wallpaperWrapper}>
            <div className={styles.absoluteContainer}>
              <div className={styles.boyContainer} ref={boyRef}>
                <img src={boy} alt="мальчик" className={styles.boy} />

                <img
                  src={closedEyes}
                  alt="моргание"
                  className={styles.closedEyes}
                  ref={closedEyesRef}
                />
              </div>
              <div className={styles.doorsContainer} ref={doorsContainerRef}>
                {tasks.map((task) => (
                  <div
                    key={task.door.id}
                    className={styles.doorWrapper}
                    data-door-id={task.door.id}
                  >
                    <img
                      src={task.door.src}
                      alt={task.door.alt}
                      className={styles.door}
                    />

                    {matchedLocks
                      .filter((m) => m.doorId === task.door.id)
                      .map((m) => {
                        const lock = tasks.find(
                          (task) => task.correctLock.id === m.lockId
                        ).correctLock;
                        return (
                          <img
                            key={m.lockId}
                            src={lock.openSrc}
                            alt={lock.alt}
                            className={styles.attachedLock}
                          />
                        );
                      })}
                  </div>
                ))}
              </div>

              {/* Отображение замков для выбора */}
              <div className={styles.locksContainer} ref={locksContainerRef}>
                {availableLocks.map((lock) => (
                  <img
                    key={lock.id}
                    src={lock.closedSrc}
                    alt={lock.alt}
                    className={styles.lock}
                    onClick={() => handleLockClick(lock)}
                    ref={(el) => (lockRefs.current[lock.id] = el)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div
            className={styles.buttonsContainer}
            // style={{
            //   pointerEvents: "auto",
            // }}
          >
            <button
              className={styles.buttonsRepeatAndSoundOnOff}
              onClick={handleSoundOnOff}
            >
              <img src={isSoundOn ? dynamicImg : dynamicOff} alt="динамик" />
              <span className={styles.tooltip}>Включить / выключить звук</span>
            </button>

            <button
              className={styles.buttonsRepeatAndSoundOnOff}
              onClick={handleRepeatTask}
            >
              <img src={repeatTaskImg} alt="повтор звука" />
              <span className={styles.tooltip}>
                Нажмите для повторного прослушивания инструкции
              </span>
            </button>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default LockersGame;
